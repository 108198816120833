import React from 'react';
import '../../styles/Modal.css';

export const CritItemEmailModal = ({ showEmailModal, closeModal, handleEmailCritItems }) => {
    
    if (!showEmailModal) return null;

    return (

        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Send Email</h3>
                <p>Are you sure you wish to send an automated email to all users that have notifications enabled and are assigned this Credit Union? <br/><br/> The body of the email will list all groups that have critical items pending, and ask that they login to review the requirements and complete the subtasks.<br/><br/></p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={closeModal}>Cancel</button>
                    <button className="btn-modal" onClick={handleEmailCritItems}>Confirm</button>
                </div>
            </div>
        </>

    );
};
