import React, { useState } from 'react';
import '../../styles/Modal.css';

const AddSubtaskModal = ({ show, onClose, subtasksLength, onAddSubtask}) => {
    const [subtaskName, setSubtaskName] = useState();
    const [subtaskDescription, setSubtaskDescription] = useState();
    const [subtaskDueDate, setSubtaskDueDate] = useState();
    const [subtaskIsComplete, setSubtaskIsComplete] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        onAddSubtask({ name: subtaskName, description: subtaskDescription, dueDate: subtaskDueDate, isComplete: subtaskIsComplete });
        setSubtaskName('');
        setSubtaskDescription('');
        setSubtaskDueDate(null);
        setSubtaskIsComplete(false);
        onClose();
    };

    if (!show) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Add New Subtask</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <input
                            type="text"
                            value={subtaskName}
                            onChange={(e) => setSubtaskName(e.target.value)}
                            placeholder="Subtask Name"
                            required
                            className="modal-input"
                        />
                        <input
                            type="text"
                            value={subtaskDescription}
                            onChange={(e) => setSubtaskDescription(e.target.value)}
                            placeholder="Subtask Description"
                            required
                            className="modal-input"
                        />
                        <input
                            type="date"
                            value={subtaskDueDate}
                            onChange={(e) => setSubtaskDueDate(e.target.value)}
                            required
                            className="modal-input"
                        />
                        <input
                            hidden
                            type="checkbox"
                            value={subtaskIsComplete}
                            onChange={(e) => setSubtaskIsComplete(e.target.value)}
                            className="modal-input"
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddSubtaskModal;
