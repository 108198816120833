import { API_URL } from "../config";
import { LoginService } from "./LoginService";
import { handleExpiredToken, handleResponse, handleError } from "../helpers/ServicesHelper";

const MeetingService = {

// meetings related //

    // Get
    getMeeting: () => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetings`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    // Get by CUID
    getMeetingsByCUID: (CUID) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetings/${CUID}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    // Get by meetingID
    getMeetingByMeetingId: (meetingID) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetings/byMeetingID/${meetingID}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    // Post
    createMeeting: (meetingData) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(meetingData),
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    // Put
    updateMeeting: (id, meetingData) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetings/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(meetingData),
        }).then(handleExpiredToken).catch(handleError);
    },

    // Delete
    deleteMeeting: (id) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetings/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).catch(handleError);
    },

    // Post - Email Meeting Notes
    EmailMeetingNotes: async (meetingID) => {
        const userToken = await LoginService.getCookie('token');
        return fetch(`${API_URL}/meetings/EmailMeetingNotes/${meetingID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).catch(handleError);
    },

// meetingGroups related //

    // Get
    getMeetingGroups: () => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetingGroups`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    // Get GROUPS by meetingID
    getGroupsByMeetingID: (meetingID) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetingGroups/${meetingID}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    // Get MEETING GROUPS by meetingID
    getMeetingGroupsByMeetingID: (meetingID) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetingGroups/getMeetingGroups/${meetingID}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    // Post
    createMeetingGroup: (meetingGroupData) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetingGroups`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(meetingGroupData),
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    // Put
    updateMeetingGroup: (id, meetingGroupData) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetingGroups/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(meetingGroupData),
        }).then(handleExpiredToken).catch(handleError);
    },

    // Delete
    deleteMeetingGroup: (id) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/meetingGroups/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).catch(handleError);
    },
};

export default MeetingService;