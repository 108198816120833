import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useCu } from '../../context/CuContext';
import TasksGauge from '../projectGauge/ProjectGauge';
import CriticalTasks from '../criticalTasks/CriticalTasks';
import GroupTaskList from './GroupTaskList';
import UpcomingMeeting from './UpcomingMeeting';
import Timeline from '../timeline/Timeline';

const Dashboard = () => {
    const { selectedCuId, selectedCuData } = useCu();

    useEffect(() => {
        localStorage.removeItem('selectedTask');
        localStorage.removeItem('selectedTaskId');
    }, []);

    return (
        <div>
            {selectedCuData && <h1>{selectedCuData.name} Dashboard</h1>}
            {selectedCuId ? (
                <>
                    <TasksGauge cuId={selectedCuId} />
                    <Timeline />
                    {/* Temporary Link to Meetings to pass state of selectedCuId while developing MeetingsSchedule.js */}
                    <UpcomingMeeting cuId={selectedCuId} />
                    <CriticalTasks cuId={selectedCuId} />
                    <GroupTaskList cuId={selectedCuId} />
                </>
            ) : (
                <p>Please select a Credit Union to display the groups.</p>
            )}
        </div>
    );
};

export default Dashboard;