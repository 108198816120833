import React, { useState } from 'react';
import '../../styles/Modal.css';

const AddGroupModal = ({ show, onClose, onAddGroup, cuId }) => {
    const [groupName, setGroupName] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onAddGroup({ name: groupName, cuId });
        setGroupName(''); // Reset the form input
        onClose();
    };

    if (!show) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Add New Group</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <input
                            type="text"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            placeholder="Group Name"
                            required
                            className="modal-input"
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddGroupModal;
