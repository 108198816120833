import './App.css';
import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Outlet, redirect } from "react-router-dom";
import { CuProvider } from './context/CuContext';
import { GroupProvider } from './context/GroupContext';
import { TaskProvider } from './context/TaskContext';
import { SubtaskProvider } from './context/SubtaskContext';
import { Login } from './components/auth/Login';
import NavigationBar from './components/common/NavigationBar';
import Dashboard from './components/dashboard/Dashboard';
import TasksView from './components/tasks/TasksView';
import SubtasksView from './components/subtasks/SubtasksView';
import MeetingNotes from './components/meetingNotes/MeetingNotes';
import MeetingsSchedule from './components/meetings/MeetingsSchedule';
import { ManageAccts } from './components/manageAccounts/ManageAccts';
import { PrivacyPolicy } from './components/auth/PrivacyPolicy';
import { ForgotPassword } from './components/auth/ForgotPassword';
import { ForgotUsername } from './components/auth/ForgotUsername';
import { ResetPassword } from './components/auth/ResetPassword';
import { CreditUnions } from './components/creditUnions/CreditUnions';
import { DisabledCUs } from './components/creditUnions/DisabledCUs';
import { UserProvider } from './context/UserContext';
import { LoginService } from './services/LoginService';
import { checkIfSalesOrAdmin } from './services/RoleService';
import { appClose, appInit } from './init';


// Layout component that includes the NavigationBar
const Layout = () => (
    <>
        <NavigationBar />
        <Outlet />
    </>
);

const isLoggedIn = () => {
    return (LoginService.getCookie('token') !== null) ? true : false
}


//redirects to login if user not logged in
const RedirectIfLoggedOut = () => {
    if (!isLoggedIn()) {
        return redirect('/');
    }
    return null;
}


//redirects to login if user not logged in AND redirects to dashboard if not Admin or Sales
const RedirectIfNotTTStaff = () => {
    if (!isLoggedIn()) {
        return redirect('/');
    }
    if (!checkIfSalesOrAdmin()) {
        return redirect('/dashboard');
    }
    return null;
}



// Creates the router outside of the App component with the Layout wrapping child routes
const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />, // Login component set as the root path
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
    },
    {
        path: "/forgot-username",
        element: <ForgotUsername />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
    },
    {
        path: "/reset-password",
        element: <ResetPassword />,
    },
    {
        path: "/", // Layout route
        element: <Layout />,
        children: [
            {
                path: "dashboard",
                element: <Dashboard />,
                //require user is Logged In
                loader: () => RedirectIfLoggedOut(),
            },
            {
                path: "tasks/:groupId",
                element: <TasksView />,
                //require user is Logged In
                loader: () => RedirectIfLoggedOut(),
                children: [
                    {
                        path: ":taskId?/:subtaskId?",
                        element: <SubtasksView />,
                        //require user is Logged In
                        loader: () => RedirectIfLoggedOut(),
                    },
                ]
            },
            {
                path: "manage-accounts",
                element: <ManageAccts />,
                //require user is TTAdmin or TTsales
                loader: () => RedirectIfNotTTStaff(),
            },
            {
                path: "credit-unions",
                element: <CreditUnions />,
                //require user is TTAdmin or TTsales
                loader: () => RedirectIfNotTTStaff(),
            },
            {
                path: "credit-unions/disabled",
                element: <DisabledCUs />,
                //require user is TTAdmin or TTsales
                loader: () => RedirectIfNotTTStaff(),
            },
            {
                path: "meetings",
                element: <MeetingsSchedule />,
                //require user is Logged In
                loader: () => RedirectIfLoggedOut(),
            },
            {
                path: "meeting-notes/:meetingID",
                element: <MeetingNotes />,
                //require user is Logged In
                loader: () => RedirectIfLoggedOut(),
            },
        ],
    },
]);

function App() {

    useEffect(() => {
        const timer = appInit();
        return () => {
            appClose(timer);
        }
    })

    return (
        <UserProvider>
            <CuProvider>
                <GroupProvider>
                    <TaskProvider>
                        <SubtaskProvider>
                            <div className="App">
                                {/* The RouterProvider will use the router configuration from above */}
                                <RouterProvider router={router} />
                            </div>
                        </SubtaskProvider>
                    </TaskProvider>
                </GroupProvider>
            </CuProvider>
        </UserProvider>
    );
}

export default App;
