import { LoginService } from "./services/LoginService";


const INACTVITY_CHECK_PERIOD_IN_MILLISECONDS = 10000;

//Sets global event listeners to update the user's last active time if they click or press keys
//Every 10 seconds, checks to see if the user has been inactive for more than 30 minutes
export const appInit = () => {

    window.addEventListener("click", LoginService.updateLastActive);
    window.addEventListener("keydown", LoginService.updateLastActive);

    return setInterval(() => {
        LoginService.checkInactivity();
    }, INACTVITY_CHECK_PERIOD_IN_MILLISECONDS);
}

// Clear timer and event listeners if the component is unmounted
export const appClose = (timer) => {
    window.removeEventListener("click", LoginService.updateLastActive);
    window.removeEventListener("keydown", LoginService.updateLastActive);
    clearInterval(timer);
}