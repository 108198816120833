import React from 'react';
import '../../styles/Modal.css';

const DeleteSubtaskModal = ({ show, onClose, onConfirmDelete }) => {
    if (!show) return null;

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Delete Subtask</h3>
                <p>Are you sure you want to delete this subtask?</p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={onClose}>Cancel</button>
                    <button className="btn-modal" onClick={onConfirmDelete}>Delete</button>
                </div>
            </div>
        </>
    );
};

export default DeleteSubtaskModal;
