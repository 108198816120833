import React, { useState, useEffect, createContext, useContext } from 'react';

const GroupContext = createContext();

export const useGroup = () => useContext(GroupContext);

export const GroupProvider = ({ children }) => {
    const [selectedGroup, setSelectedGroup] = useState();
    const [selectedGroupData, setSelectedGroupData] = useState();
    const [groupName, setGroupName] = useState();

    const setGroupAndName = (groupData) => {
        setSelectedGroupData(groupData);
        setGroupName(groupData ? groupData.name : null);
    };

    return (
        <GroupContext.Provider value={{ selectedGroupData, setGroupName, groupName, setGroupAndName}}>
            {children}
        </GroupContext.Provider>
    );
};

export default GroupContext;