import React, { useState, useEffect } from 'react';
import '../../styles/Modal.css';
import GroupService from '../../services/GroupService';
import { useCu } from '../../context/CuContext';
import { DatetimeHelper } from '../../helpers/DatetimeHelper';


const AddMeetingModal = ({ show, onClose, handleAddMeeting }) => {
    const { selectedCuId } = useCu();
    const [meetingDueDate, setMeetingDueDate] = useState();
    const [meetingTopics, setMeetingTopics] = useState('');
    const [allGroups, setAllGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        fetchAllGroups();
    }, []);

    const fetchAllGroups = async () => {
        const fetchedGroups = await GroupService.getGroupsByCuId(selectedCuId);
        setAllGroups(fetchedGroups);
    }

    const handleCheckboxChange = (group) => {
        // Add or Remove (filter out and set) group based on whether it is already included in the selectedGroups array, or not
        if (selectedGroups.includes(group)) {
            setSelectedGroups(selectedGroups.filter(t => t !== group));
        } else {
            setSelectedGroups([...selectedGroups, group]);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        //convert time from local to UTC before sending
        const convertedDatetime = DatetimeHelper.convertToUTC(meetingDueDate)

        handleAddMeeting({ date: convertedDatetime, topics: meetingTopics }, selectedGroups);
        setMeetingTopics(''); // Reset the form input
        onClose();
    };

    if (!show) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Add New Meeting</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <input
                            type="text"
                            value={meetingTopics}
                            onChange={(e) => setMeetingTopics(e.target.value)}
                            placeholder="Meeting Topics"
                            required
                            className="modal-input"
                        />
                        <div className="template-groups-container">
                            {allGroups.length > 0 ? (allGroups.map((group) => (
                                <div key={`group--${group.id}`}>
                                    <input
                                        type="checkbox"
                                        id={`group-checkbox-${group.id}`}
                                        checked={selectedGroups.includes(group)}
                                        onChange={() => handleCheckboxChange(group)}
                                    />
                                    <label htmlFor={`group-checkbox-${group.id}`}>{group.name}</label>
                                </div>
                            
                            ))) : ('is loading')}
                        </div>
                        <input
                            type="datetime-local"
                            value={meetingDueDate}
                            onChange={(e) => setMeetingDueDate(e.target.value)}
                            required
                            className="modal-input"
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddMeetingModal;
