import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useGroup } from '../../context/GroupContext';
import { useTask } from '../../context/TaskContext';
import GroupService from '../../services/GroupService';
import AddGroupModal from './AddGroupModal';
import DeleteGroupModal from './DeleteGroupModal';
import EditGroupModal from './EditGroupModal';
import '../../styles/GroupTaskList.css';
import { useUserContext } from '../../context/UserContext';

const GroupTaskList = ({ cuId }) => {

    const { isSalesOrAdmin } = useUserContext();

    const noGroupsMessage = "Select 'Add Group' to display Group completion percentages.";
    const { setGroupAndName } = useGroup();
    const { setSelectedTaskData } = useTask();
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [groupToEdit, setGroupToEdit] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(null);

    useEffect(() => {
        if (cuId) {
            fetchGroups();
        }
    }, [cuId]);

    const fetchGroups = async () => {
        setIsLoading(true);
        try {
            const response = await GroupService.getGroupsWithCompletionByCuId(cuId);
            setGroups(response);
        } catch (error) {
            console.error('Fetching groups failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddGroup = async (groupData) => {
        try {
            await GroupService.createGroup({ ...groupData, cuId });
            fetchGroups();
        } catch (error) {
            console.error('Creating group failed', error);
        }
        setShowModal(false);
    };

    const handleEditGroup = async (groupData) => {
        try {
            await GroupService.updateGroup(groupData.id, groupData);
            fetchGroups();
        } catch (error) {
            console.error('Editing group failed', error);
        }
        setShowEditModal(false);
    };

    const handleDeleteGroup = async () => {
        try {
            await GroupService.deleteGroup(groupToDelete);
            fetchGroups();
        } catch (error) {
            console.error('Deleting group failed', error);
        }
        setShowDeleteModal(false);
    };

    const promptEditGroup = (group) => {
        setGroupToEdit(group);
        setShowEditModal(true);
    };

    const promptDeleteGroup = (groupId) => {
        setGroupToDelete(groupId);
        setShowDeleteModal(true);
    };

    const navLinkClickHandler = (group) => {
        setGroupAndName(group)
        localStorage.setItem('selectedGroup', JSON.stringify(group));

    }

    return (
        <div>
            <h2>Groups</h2>
            {isLoading ? (
                <div>Loading...</div>
            ) : groups.length > 0 ? (
                <div className="group-grid">
                    {groups.map(group => (
                        <div key={group.id} className="group-card">
                            {/* Edit and Delete buttons visible only if user's role TTadmin or TTsales */}
                            {isSalesOrAdmin ? <>
                                <button className="edit-button" onClick={() => promptEditGroup(group)}>✏️</button>
                                <button className="delete-button" onClick={() => promptDeleteGroup(group.id)}>X</button>
                            </>
                                : <></>
                            }
                            <NavLink
                                to={`../tasks/${group.id}`}
                                onClick={() => navLinkClickHandler(group)}
                                className={"nav-link"}
                            >
                                        {group.name}
                                    <p>{`${group.percentage.toFixed(0)}% Complete`}</p>
                            </NavLink>
                        </div>
                    ))}
                </div>
            ) : (
                <p>{noGroupsMessage}</p>
            )}

            {/* Add Group button only visible if user's role TTadmin or TTsales */}
            {isSalesOrAdmin ?
                <button onClick={() => setShowModal(true)} className="add-group-button">➕ Add Group</button>
                : <div className="blank-space"></div>
            }

            {showModal && <AddGroupModal show={showModal} onClose={() => setShowModal(false)} onAddGroup={handleAddGroup} />}
            {showEditModal && <EditGroupModal show={showEditModal} onClose={() => setShowEditModal(false)} onEditGroup={handleEditGroup} groupToEdit={groupToEdit} />}
            {showDeleteModal && <DeleteGroupModal show={showDeleteModal} onClose={() => setShowDeleteModal(false)} onConfirmDelete={handleDeleteGroup} />}
        </div>
    );
};

export default GroupTaskList;