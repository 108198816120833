import React, { useEffect, useState } from 'react';
import '../../styles/Modal.css';
import { getAllRoles } from '../../services/RoleService';
import { useUserContext } from '../../context/UserContext';

export const AddUserModal = ({ showAddModal, closeModal, handleAddUser }) => {

    const { isAdmin } = useUserContext();
    const [roleList, setRoleList] = useState([]);

    const [newUserData, setNewUserData] = useState({
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        role: 3,
        receivesNotifs: false,
    });

    useEffect(() => {
        getAllRoles().then((roles) => setRoleList(roles));
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        handleAddUser(newUserData)
        closeModal();
    };

    if (!showAddModal) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Add New User</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">

                        <input
                            type="text"
                            value={newUserData.firstName}
                            onChange={
                                (event) => {
                                    const copy = { ...newUserData }
                                    copy.firstName = event.target.value
                                    setNewUserData(copy)
                                }
                            }
                            placeholder="First Name"
                            required
                            className="modal-input"
                        />

                        <input
                            type="text"
                            value={newUserData.lastName}
                            onChange={
                                (event) => {
                                    const copy = { ...newUserData }
                                    copy.lastName = event.target.value
                                    setNewUserData(copy)
                                }
                            }
                            placeholder="Last Name"
                            required
                            className="modal-input"
                        />

                        <input
                            type="text"
                            value={newUserData.username}
                            onChange={
                                (event) => {
                                    const copy = { ...newUserData }
                                    copy.username = event.target.value
                                    setNewUserData(copy)
                                }
                            }
                            placeholder="Username"
                            required
                            className="modal-input"
                        />

                        <input
                            type="text"
                            value={newUserData.email}
                            onChange={
                                (event) => {
                                    const copy = { ...newUserData }
                                    copy.email = event.target.value
                                    setNewUserData(copy)
                                }
                            }
                            placeholder="Email"
                            required
                            className="modal-input"
                        />

                        {/* ONLY display role dropdown if user is TTadmin */}
                        {isAdmin ?
                            <div className='dropdown-container'>
                                <label htmlFor='role-input'>Role</label>
                                <select
                                    required
                                    id='role-input'
                                    className="modal-input dropdown"
                                    name="role"
                                    value={newUserData.role}
                                    onChange={(event) => {
                                        const copy = { ...newUserData }
                                        copy.role = event.target.value
                                        setNewUserData(copy)
                                    }}
                                >
                                    {roleList.map((role) => (
                                        <option key={role.id} value={role.id}>{role.roleName}</option>
                                    ))}
                                </select>
                            </div>
                            : <></>
                        }

                        <div className='checkbox-container'>
                            <label htmlFor="receivesNotifs-checkbox">Receives Notifications</label>
                            <input
                                id='receivesNotifs-checkbox'
                                className="modal-input checkbox"
                                type="checkbox"
                                value={newUserData.receivesNotifs}
                                onChange={
                                    (event) => {
                                        const copy = { ...newUserData }
                                        copy.receivesNotifs = event.target.checked
                                        setNewUserData(copy)
                                    }
                                }
                            />
                        </div>



                    </div>

                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={closeModal}>Cancel</button>
                    </div>

                </form>

            </div>
        </>
    );
};