import React, { useState, useEffect } from 'react';
import { DatetimeHelper } from '../../helpers/DatetimeHelper';
import MeetingService from "../../services/MeetingService";
import GroupService from "../../services/GroupService";
import { useCu } from "../../context/CuContext";
import '../../styles/Modal.css';

const EditMeetingModal = ({ show, onClose, selectedMeetingID, meetings, handleEditMeeting }) => {
    const { selectedCuId } = useCu();
    const [selectedMeeting, setSelectedMeeting] = useState({});
    const [initialMeetingGroups, setInitialMeetingGroups] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [allGroupsWithChecked, setAllGroupsWithChecked] = useState([]);
    const [updatedDateTime, setUpdatedDateTime] = useState('');
    const [updatedTopics, setUpdatedTopics] = useState('');

    const [meetingAgenda, setMeetingAgenda] = useState("");
    const [meetingActionItems, setMeetingActionItems] = useState("");
    const [meetingDiscussionNotes, setMeetingDiscussionNotes] = useState("");
    const [meetingDecisionsMade, setMeetingDecisionsMade] = useState("");

    useEffect(() => {
        handleInitialRender();
    }, []);

    useEffect(() => {
        setSelectedMeetingGroups();
    }, [allGroups, initialMeetingGroups]);

    const handleInitialRender = async () => {
        const response = await MeetingService.getMeetingByMeetingId(selectedMeetingID)
        setMeetingAgenda(response.agenda);
        setMeetingActionItems(response.actionItems);
        setMeetingDiscussionNotes(response.discussionNotes);
        setMeetingDecisionsMade(response.decisionsMade);
        await fetchGroupsByMeetingId();
        await fetchAllGroups();
    }

    useEffect(() => {
        if (meetings.length > 0) {
            const formatMeeting = () => {
                const filterResult = meetings.find(meeting => meeting.id === selectedMeetingID);
                if (filterResult) {
                    setUpdatedDateTime(filterResult.date);
                    setSelectedMeeting(filterResult);
                    setUpdatedTopics(filterResult.topics);
                }
            };
            formatMeeting();
        }
    }, [selectedMeetingID, meetings]);

    const handleDateTimeUpdate = (e) => {
        setUpdatedDateTime(e.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const convertedDatetime = DatetimeHelper.convertToUTC(updatedDateTime);
        handleEditMeeting({ id: selectedMeetingID, date: convertedDatetime, topics: updatedTopics, agenda: meetingAgenda, actionItems: meetingActionItems, discussionNotes: meetingDiscussionNotes, decisionsMade: meetingDecisionsMade }, allGroupsWithChecked);
        onClose();
    };

    const fetchGroupsByMeetingId = async () => {
        const thisMeetingsInitialGroups = await MeetingService.getGroupsByMeetingID(selectedMeetingID);
        setInitialMeetingGroups(thisMeetingsInitialGroups);
    };

    const fetchAllGroups = async () => {
        if (!selectedCuId) {
            console.error('selectedCuId is undefined, skipping fetch for all groups.');
            return;
        }

        try {
            const fetchedGroups = await GroupService.getGroupsByCuId(selectedCuId);
            setAllGroups(fetchedGroups);
        } catch (error) {
            console.error('Failed to fetch groups:', error);
        }
    };

    const setSelectedMeetingGroups = () => {
        const updatedAllGroups = allGroups.map(group => ({ ...group, checked: initialMeetingGroups.some(initialGroup => initialGroup.id === group.id) }));
        setAllGroupsWithChecked(updatedAllGroups);
    };

    const updateCheckedGroup = (event) => {
        const [, groupId] = event.target.id.split("--")
        const parsedGroupId = parseInt(groupId, 10);
        const updatedGroups = allGroupsWithChecked.map(group => {
            if (group.id === parsedGroupId) {
                return { ...group, checked: !group.checked };
            }
            return group;
        });
        setAllGroupsWithChecked(updatedGroups);
    };

    if (!show) return null;

    return (
        <div>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Edit Meeting</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <input
                            type="text"
                            placeholder="Topics"
                            value={updatedTopics}
                            onChange={(e) => setUpdatedTopics(e.target.value)}
                            className="modal-input"
                            required
                        />
                        <div>
                            {allGroupsWithChecked.length > 0 ? allGroupsWithChecked.map(group => (
                                <div key={`group--${group.id}`}>
                                    <input
                                        type="checkbox"
                                        id={`group-checkbox--${group.id}`}
                                        checked={group.checked}
                                        onChange={updateCheckedGroup}
                                        className="modal-input"
                                    />
                                    <label htmlFor={`group-checkbox--${group.id}`}>{group.name}</label>
                                </div>
                            )) : <p>...is loading</p>}
                        </div>
                        <input
                            type="datetime-local"
                            value={updatedDateTime}
                            onChange={handleDateTimeUpdate}
                            required
                            className="modal-input"
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditMeetingModal;