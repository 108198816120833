import React, { useState, useEffect } from 'react';
import { useTask } from '../../context/TaskContext';
import SubtaskService from '../../services/SubtaskService';
import AddSubtaskModal from './AddSubtaskModal';
import EditSubtaskModal from './EditSubtaskModal';
import DeleteSubtaskModal from './DeleteSubtaskModal';
import '../../styles/SubtasksView.css';
import { AttachmentService } from '../../services/AttachmentService';
import { AddAttachmentModal } from '../common/AddAttachmentModal';
import { ViewAttachmentModal } from '../common/ViewAttachmentsModal';
import { DeleteAttachmentModal } from '../common/DeleteAttachmentModal';
import { useUserContext } from '../../context/UserContext';

const SubtasksView = ({ editTask, deleteTaskAndSubtasks, setSubtaskCompletionPercentage, subtaskCompletionPercentage }) => {
    const noSubtasksMessage = "No subtasks available. Add a new subtask to get started.";

    const { isSalesOrAdmin } = useUserContext();
    const { selectedTaskData, setSelectedTaskData } = useTask();
    const taskId = selectedTaskData.id;
    const [currentTaskName, setCurrentTaskName] = useState('');
    const [subtasks, setSubtasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false);
    const [showViewAttachmentsModal, setShowViewAttachmentsModal] = useState(false);
    const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] = useState(false);

    const [attachments, setAttachments] = useState([]);
    const [addAttachmentSubtaskID, setAddAttachmentSubtaskID] = useState();
    const [viewAttachmentsSubtask, setViewAttachmentsSubtask] = useState();
    const [attachmentToDelete, setAttachmentToDelete] = useState();

    const [subtaskToEdit, setSubtaskToEdit] = useState(null);
    const [subtaskToDelete, setSubtaskToDelete] = useState(null);
    const [totalSubtasks, setTotalSubtasks] = useState(0);
    const [completedSubtasks, setCompletedSubtasks] = useState(0);
    const [remainingSubtasks, setRemainingSubtasks] = useState(0);

    useEffect(() => {
        const storedTask = JSON.parse(localStorage.getItem('selectedTask'));
        if (storedTask) {
            setSelectedTaskData(storedTask);
            setCurrentTaskName(storedTask.name);
        }
    }, []);

    useEffect(() => {
        setSubtaskCompletionPercentage(calculateCompletionPercentage());
    }, [completedSubtasks])

    useEffect(() => {
        if (selectedTaskData) {
            setCurrentTaskName(selectedTaskData.name);
            fetchSubtasks();
        }
    }, [selectedTaskData]);

    const fetchSubtasks = async () => {
        setIsLoading(true);
        try {
            const response = await SubtaskService.getSubtasksByTaskId(selectedTaskData.id);
            setSubtasks(response);
            setTotalSubtasks(response.length);
            updateSubtaskCounts(response);
        } catch (error) {
            console.error('Fetching subtasks failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateSubtaskCounts = (subtasks) => {
        const completedCount = subtasks.filter(subtask => subtask.isComplete).length;
        setCompletedSubtasks(completedCount);
        setRemainingSubtasks(subtasks.length - completedCount);
    };

    const handleSubtaskCompletionChange = async (subtask) => {
        const response = await calculateCompletionPercentage();
        setSubtaskCompletionPercentage(response);
        const updatedSubtask = { ...subtask, isComplete: !subtask.isComplete };
        try {
            await SubtaskService.updateSubtask(subtask.id, updatedSubtask);
            const updatedSubtasks = subtasks.map(st => st.id === subtask.id ? updatedSubtask : st);
            setSubtasks(updatedSubtasks);
            updateSubtaskCounts(updatedSubtasks);
        } catch (error) {
            console.error('Updating subtask failed', error);
        }
    };

    const getFormattedDate = (dueDate) => {
        return new Date(dueDate).toLocaleDateString();
    };

    const handleAddSubtask = async (subtaskData) => {
        try {
            await SubtaskService.createSubtask({ ...subtaskData, taskId });
            fetchSubtasks();
        } catch (error) {
            console.error('Creating subtask failed', error);
        }
        setShowAddModal(false);
    };

    const handleEditSubtask = async (subtaskData) => {
        try {
            await SubtaskService.updateSubtask(subtaskData.id, subtaskData);
            fetchSubtasks();
        } catch (error) {
            console.error('Editing subtask failed', error);
        }

        setShowEditModal(false);
    };

    const handleDeleteSubtask = async () => {
        try {
            await SubtaskService.deleteSubtask(subtaskToDelete);
            fetchSubtasks();
        } catch (error) {
            console.error('Deleting subtask failed', error);
        }
        setShowDeleteModal(false);
    };

    const handleEditTask = () => {
        editTask(selectedTaskData);
    }

    const promptEditSubtask = (subtask) => {
        setSubtaskToEdit(subtask);
        setShowEditModal(true);
    };

    const promptDeleteSubtask = (subtaskId) => {
        setSubtaskToDelete(subtaskId);
        setShowDeleteModal(true);
    };

    const calculateCompletionPercentage = () => {
        if (totalSubtasks === 0) return 0; // To avoid division by zero
        return Math.round((completedSubtasks / totalSubtasks) * 100);
    };

    const promptAddAttachment = (subtaskID) => {
        setAddAttachmentSubtaskID(subtaskID);
        setShowAddAttachmentModal(true);
    };

    const promptViewAttachments = async (subtask) => {
        try {
            await setViewAttachmentsSubtask(subtask)
            const response = await AttachmentService.getAttachmentsBySubtask(subtask.id)
            await setAttachments(response);
            setShowViewAttachmentsModal(true)
        } catch (error) {
            console.error('Failed to fetch attachments', error);
        }
    };

    const promptDeleteAttachment = (attachment) => {
        setAttachmentToDelete(attachment)
        setShowViewAttachmentsModal(false);
        setShowDeleteAttachmentModal(true)
    };

    const handleAddAttachment = async (formData) => {
        try {
            await AttachmentService.uploadAttachment(formData, 0, addAttachmentSubtaskID);
        } catch (error) {
            console.error('Failed to upload attachment', error);
        }
        setShowAddModal(false);
    };

    const handleDownloadAttachment = async (attachmentKey, attachmentName) => {
        try {
            await AttachmentService.downloadAttachment(attachmentKey, attachmentName);
        } catch (error) {
            console.error('Failed to download attachment', error);
        }
        setShowAddModal(false);
    };

    const closeDeleteAttachmentModal = async () => {
        await setShowDeleteAttachmentModal(false);
        setShowViewAttachmentsModal(true);
    }

    const handleDeleteAttachment = async (id) => {
        try {
            //delete attachment from DB and Bucket
            await AttachmentService.deleteAttachment(id);
        } catch (error) {
            console.error('Failed to delete attachment', error);
        }

        //after deletion, close delete modal
        await setShowDeleteAttachmentModal(false);

        //re-render ViewAttachments modal by setting viewAttachmentsSubtask to its current value
        await promptViewAttachments(viewAttachmentsSubtask)

        //show ViewAttachments modal
        setShowViewAttachmentsModal(true);

    };

    return (
        <div className="subtasks-component">
            <h2>{'Subtasks'}</h2>

            {isLoading ? (
                <div>Loading...</div>
            ) : subtasks.length > 0 ? (
                <div className="subtasks-view">
                    <div className="subtasks-table">
                        <div className="subtasks-header">
                            <div className="subtasks-header-top">
                                <div className="subtasks-edit-task-name">

                                </div>
                                <div className="subtask-task-name">
                                    <h2>{currentTaskName}</h2>
                                </div>
                                <div className="subtask-delete-task">

                                </div>
                            </div>
                            <div className="subtasks-completion-percentage">
                                <p>Subtasks Completed: {subtaskCompletionPercentage}%</p>
                                <div className="subtasks-progress-bar">
                                    <div className="subtasks-progress" style={{ width: `${subtaskCompletionPercentage}%` }}></div>
                                </div>
                            </div>
                            <div className="subtasks-totals">
                                <div className="subtasks-total-subtasks">
                                    <h4>Subtasks: {totalSubtasks}</h4>
                                </div>
                                <div className={`subtasks-remaining ${remainingSubtasks > 0 ? 'remaining-subtasks' : ''}`}>
                                    <h4>Remaining: {remainingSubtasks}</h4>
                                </div>
                                <div className={`subtasks-completed ${completedSubtasks > 0 ? 'completed-subtasks' : ''}`}>
                                    <h4>Completed: {completedSubtasks}</h4>
                                </div>
                            </div>
                            <div className="subtasks-fields">
                                <div className="subtasks-fields-name">
                                    <h4>Name</h4>
                                </div>
                                <div className="subtasks-fields-due-date">
                                    <h4>Due Date</h4>
                                </div>
                                <div className="subtasks-fields-completion-status">
                                    <h4>Completion Status</h4>
                                </div>
                            </div>
                        </div>
                        {subtasks.map(subtask => (
                            <div key={subtask.id} className="subtasks-body">
                                <div className="subtask-records">
                                    <div className="subtask-completed-field-header">
                                        <p>Complete</p>
                                    </div>
                                    <div className="subtask-data">
                                        <div className="subtask-name">
                                            <h4>{subtask.name}</h4>
                                        </div>
                                        <div className="subtask-due-date">
                                            <p>{getFormattedDate(subtask.dueDate)}</p>
                                        </div>
                                        <div className="subtask-data-right">
                                            <div className="subtask-hover-actions">

                                                {/* Edit and Delete buttons visible only if user's role TTadmin or TTsales */}
                                                {isSalesOrAdmin ? <>
                                                    <button className="subtasks-edit-subtask-button" onClick={() => promptEditSubtask(subtask)} aria-label="Edit">✏️</button>
                                                    <button className="subtasks-delete-subtask-button" onClick={() => promptDeleteSubtask(subtask.id)} aria-label="Delete">X</button>
                                                </>
                                                    : <></>
                                                }

                                                <button className="subtasks-add-attachment-button" onClick={() => promptAddAttachment(subtask.id)} aria-label="Add Attachment">📎</button>
                                            </div>
                                            <div className="subtask-checkbox">
                                                {/* make checkbox readonly if user not admin or sales */}
                                                {isSalesOrAdmin ?
                                                    <input
                                                        type="checkbox"
                                                        name="completion-status"
                                                        checked={subtask.isComplete}
                                                        onChange={() => handleSubtaskCompletionChange(subtask)}
                                                    />
                                                    : <input
                                                        readOnly
                                                        type="checkbox"
                                                        name="completion-status"
                                                        checked={subtask.isComplete}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="subtask-description">
                                        <div className="subtask-description-text">
                                            <p>{subtask.description}</p>
                                        </div>
                                        <div className="subtask-description-view-attachments">
                                            <button className="subtask-view-attachment" onClick={() => promptViewAttachments(subtask)}>View Attachments</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="no-subtasks">{noSubtasksMessage}</div>
            )}
            <div className="no-subtasks-add-subtask">

                {/* Add Subtask button only visible if user's role TTadmin or TTsales */}
                {isSalesOrAdmin ? <>
                    <button onClick={() => setShowAddModal(true)} className="no-subtasks-add-subtask-button">➕Add Subtask</button>
                </>
                    : <></>
                }

            </div>

            {showAddModal && <AddSubtaskModal show={showAddModal} onClose={() => setShowAddModal(false)} onAddSubtask={handleAddSubtask} taskId={taskId} subtasksLength={subtasks.length} />}
            {showEditModal && <EditSubtaskModal show={showEditModal} onClose={() => setShowEditModal(false)} onEditSubtask={handleEditSubtask} subtaskToEdit={subtaskToEdit} taskId={taskId} />}
            {showDeleteModal && <DeleteSubtaskModal show={showDeleteModal} onClose={() => setShowDeleteModal(false)} onConfirmDelete={handleDeleteSubtask} subtaskIdToDelete={subtaskToDelete} />}

            {/* Attachment Modals */}
            {showAddAttachmentModal && <AddAttachmentModal showModal={showAddAttachmentModal} closeModal={() => setShowAddAttachmentModal(false)} handleAddAttachment={handleAddAttachment} />}
            {showViewAttachmentsModal && <ViewAttachmentModal showModal={showViewAttachmentsModal} closeModal={() => setShowViewAttachmentsModal(false)} subtaskOrMeetingName={viewAttachmentsSubtask.name} handleDownloadAttachment={handleDownloadAttachment} promptDeleteAttachment={promptDeleteAttachment} attachments={attachments} modalType={'subtask'}/>}
            {showDeleteAttachmentModal && <DeleteAttachmentModal showModal={showDeleteAttachmentModal} closeModal={closeDeleteAttachmentModal} handleDeleteAttachment={handleDeleteAttachment} attachmentToDelete={attachmentToDelete} modalType={'subtask'} />}
        </div>
    );
};

export default SubtasksView;
