import { API_URL } from "../config";
import { LoginService } from "./LoginService";
import { handleExpiredToken, handleResponse, handleError } from "../helpers/ServicesHelper";

const UserService = {

    getAllUsers: () => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Users`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    getUserById: (userID) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Users/${userID}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },


    createUser: (newUser) => {

        newUser.password = 'thisIsNotReallyAPasswordButMustHaveAValueWhenSent'

        const userToken = LoginService.getCookie('token')

        return fetch(`${API_URL}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(newUser),
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    editUser: (editedUser) => {

        const userToken = LoginService.getCookie('token')

        return fetch(`${API_URL}/users/${editedUser.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(editedUser),
        }).then(handleExpiredToken).catch(handleError);
    },

    deleteUser: (id) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/users/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).catch(handleError);
    },


};

export default UserService;