import { API_URL } from "../config";
import { LoginService } from "./LoginService";
import { handleExpiredToken, handleResponse, handleError } from "../helpers/ServicesHelper";

const TaskService = {

    getTasks: () => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Tasks`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    getTasksByGroupId: (groupId) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Tasks/${groupId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    createTask: async (taskData) => {
        try {
            const userToken = LoginService.getCookie('token');
            const response = await fetch(`${API_URL}/Tasks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify(taskData),
            });
            const checkedResponse = await handleExpiredToken(response)
            return await handleResponse(checkedResponse);
        } catch (error) {
            handleError(error);
        }
    },

    updateTask: (id, taskData) => {
        const userToken = LoginService.getCookie('token');
        return fetch(`${API_URL}/Tasks/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(taskData),
        }).then(handleExpiredToken).catch(handleError);
    },

    deleteTask: (id) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Tasks/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).catch(handleError);
    },
};

export default TaskService;