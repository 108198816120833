import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoginService } from '../../services/LoginService';
import '../../styles/Auth.css';
import { useUserContext } from '../../context/UserContext';
import { checkIfAdmin, checkIfSalesOrAdmin } from '../../services/RoleService';
import TTLogo from '../../assets/Logo.png';

export function Login() {
    const [usernameState, setUsername] = useState('');
    const [passwordState, setPassword] = useState('');
    const { isLoggedIn, setIsLoggedIn, setIsAdmin, setIsSalesOrAdmin } = useUserContext();

    useEffect(() => {
        if (isLoggedIn === true) {
            navigate('/dashboard');
        }
    }, [isLoggedIn]);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Create the credentials object
        const credentials = {
            username: usernameState, // Username is case-sensitive
            password: passwordState,
        };

        // Ensure credentials are present before attempting login
        const checkedResponse = LoginService.checkLoginRequirements(usernameState, passwordState);
        if (!checkedResponse.canLogin) {
            alert('INVALID LOGIN. Please ensure both username and password are provided.');
            return;
        }

        // Attempt login with the backend
        await LoginService.handleLogin(credentials)
            .then((data) => {
                if (data) {
                    LoginService.saveNewUser(data);
                    setIsLoggedIn(true);
                    setIsAdmin(checkIfAdmin());
                    setIsSalesOrAdmin(checkIfSalesOrAdmin());
                    navigate('/dashboard');
                }
            })
            .catch((error) => {
                if (error && error.message) {
                    // Display feedback based on the backend response
                    alert(`LOGIN FAILED: ${error.message}`);
                } else {
                    alert('INVALID LOGIN. Please try again or reset your password.');
                    console.error('Login failed', error);
                }
            });
    };

    return (
        <main id="login" className="page">
            <img src={TTLogo} alt="logo" className="logo" />

            <section aligncontent="center" className="page-form">
                <form onSubmit={handleSubmit} className="page-inner-form">
                    <h3 className="page-text">Login</h3>

                    <label htmlFor="username" className="page-text">
                        Username
                    </label>
                    <input
                        onChange={(e) => setUsername(e.target.value)}
                        name="username"
                        id="username"
                        autoFocus
                        value={usernameState}
                        autoComplete="off"
                        className="page-input"
                    />

                    <div className="password-field-container">
                        <label htmlFor="password" className="page-text">
                            Password
                        </label>
                        <div className="password-input-with-tooltip">
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                name="password"
                                id="password"
                                value={passwordState}
                                autoComplete="off"
                                className="page-input"
                            />
                        </div>
                    </div>

                    <button className="page-button" type="submit">
                        Submit
                    </button>
                </form>

                <Link to="/forgot-username" className="page-link">
                    Forgot Username
                </Link>

                <Link to="/forgot-password" className="page-link">
                    Forgot Password
                </Link>

                <Link to="/privacy-policy" className="page-link">
                    Privacy Policy
                </Link>
            </section>
        </main>
    );
}
