import React, { useState, useEffect } from 'react';
import GroupService from '../../services/GroupService';
import TaskService from '../../services/TaskService';
import SubtaskService from '../../services/SubtaskService';
import '../../styles/ProjectGauge.css';

const ProjectGauge = ({ cuId }) => {
    const [percentageCompleted, setPercentageCompleted] = useState(0);

    useEffect(() => {
        getCompletionPercentage()
    }, [cuId]);

    const getCompletionPercentage = async () => {
        const response = await GroupService.getOverallCompletionByCuId(cuId);
        setPercentageCompleted(response);
    };

    return (
        <>
            <div className="tasks-gauge-container">
                <h2>Project Completion</h2>
                <div className="gauge-progress-container">
                    <div className="gauge-progress-circle" style={{ '--percentage-degree': `${percentageCompleted * 3.6}deg` }}>
                        <div className="gauge-inner-circle">
                            <div className="gauge-percentage">
                                {percentageCompleted.toFixed(0)}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectGauge;
