import { API_URL } from "../config";
import { LoginService } from "./LoginService";
import { handleExpiredToken, handleResponse, handleError } from "../helpers/ServicesHelper";

const CreditUnionService = {

    getAllCreditUnions: async () => {
        const userToken = await LoginService.getCookie('token')
        return fetch(`${API_URL}/CreditUnions`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    getAllDisabledCUs: async () => {
        const userToken = await LoginService.getCookie('token')
        return fetch(`${API_URL}/CreditUnions/getDisabled`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    getCreditUnionById: async (id) => {
        const userToken = await LoginService.getCookie('token')
        return fetch(`${API_URL}/CreditUnions/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    createCreditUnion: async (newCUname, newCUlogo) => {
        try {
            const userToken = LoginService.getCookie('token');
            const response = await fetch(`${API_URL}/CreditUnions/?name=${newCUname}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + userToken
                },
                body: newCUlogo
            });

            const checkedResponse = await handleExpiredToken(response)
            return await handleResponse(checkedResponse);
            
        } catch (error) {
            handleError(error);
        }
    },

    updateCreditUnion: (id, creditUnionData) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/creditUnions/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(creditUnionData)
        })
            .then(handleExpiredToken).catch(handleError);
    },

    deleteCreditUnion: (id) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        })
            .then(handleExpiredToken)
            .then(handleResponse)
            .catch(handleError);
    },


    //credit union assignment functions
    getUsersCreditUnionsByUserId: async (id) => {
        const userToken = await LoginService.getCookie('token')
        return fetch(`${API_URL}/usersCreditUnions/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    removeAUsersCreditUnion: async (usersCreditUnionID) => {
        const userToken = await LoginService.getCookie('token')
        return fetch(`${API_URL}/usersCreditUnions/${usersCreditUnionID}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).catch(handleError)
    },

    addACreditUnionToUser: async (userID, cuID) => {
        const userToken = await LoginService.getCookie('token')
        return fetch(`${API_URL}/usersCreditUnions/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify({
                userId: userID,
                cuId: cuID
            })
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    getCULogo: async (brandingLogoKey) => {
        try {
            const userToken = await LoginService.getCookie('token');
            const response = await fetch(`${API_URL}/creditUnions/getLogoURL/?key=${brandingLogoKey}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + userToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch img url');
            }

            //If token is expired, inform user, logout, and redirect
            const checkedResponse = await handleExpiredToken(response)

            const data = await checkedResponse.text();
            return data;
        }
        catch (error) {
            return handleError(error)
        }
    },

    updateCULogo: (id, newLogoFormData) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/creditUnions/updateLogo/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
            body: newLogoFormData
        })
        .then(handleExpiredToken).catch(handleError);
    },

    emailCritItems: async (cuID, groups) => {
        const userToken = await LoginService.getCookie('token')
        return fetch(`${API_URL}/usersCreditUnions/EmailCritItems/${cuID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(groups)
        }).then(handleExpiredToken).catch(handleError);
    },





};

export default CreditUnionService;
