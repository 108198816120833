import { PassUserProvider } from "../context/UserContext";

export const handleExpiredToken = (response) => {
    if (response.status === 401 || response.status === 403) {
        PassUserProvider.handleLogout();
        return Promise.reject();
    }
    else {
        return response
    }
}

export const handleResponse = (response) => {
    if (!response.ok) {
        return response.json().then(body => Promise.reject(body));
    }
    return response.json();
};

export const handleError = (error) => {
    console.error('API call failed:', error);
    return Promise.reject(error);
};