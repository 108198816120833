import '../../styles/ManageAccts.css';
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import CreditUnionService from '../../services/CreditUnionService';
import { EnableModal } from './EnableModal';

export const DisabledCUs = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [disabledCUs, setDisabledCUs] = useState([]);
    const [CUToEnable, setCUToEnable] = useState();
    const [showEnableModal, setShowEnableModal] = useState(false);

    useEffect(() => {
        fetchCreditUnions();
    }, [])

    const fetchCreditUnions = async () => {
        await setIsLoading(true);
        try {
            //fetch disabled CUs
            const listOfCreditUnions = await CreditUnionService.getAllDisabledCUs();

            // Add logoURL to each creditUnion, with new presigned URL fetched from S3
            const updatedList = [];
            for (const cu of listOfCreditUnions) {
                cu.logoURL = await CreditUnionService.getCULogo(cu.brandingLogo);
                updatedList.push(cu);
            }

            setDisabledCUs(updatedList)

        } catch (error) {
            console.error('Fetching Credit Unions failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEnablePrompt = async (event) => {
        event.preventDefault();
        const [, CUID] = event.target.id.split("--")
        const parsedCUID = parseInt(CUID)
        const foundCU = await CreditUnionService.getCreditUnionById(parsedCUID)
        await setCUToEnable(foundCU)
        await setShowEnableModal(true)
    }

    const handleEnableSubmit = async () => {
        try {
            //update CU.disabled to FALSE
            const updatedCU = await { 
                id: CUToEnable.id,
                name: CUToEnable.name,
                brandingLogo: CUToEnable.brandingLogo,
                disabled: false
            }

            await CreditUnionService.updateCreditUnion(updatedCU.id, { ...updatedCU });
            fetchCreditUnions();

        } catch (error) {
            console.error('Failed to disable credit union', error);
        }
        setShowEnableModal(false);
    };

    return (

        <>
            <h1>Disabled Credit Unions</h1>
            <div className="manageacct-buttons">
                <NavLink to='/credit-unions'>
                    <button className='manageacct-button'>Back to Active Credit Unions</button>
                </NavLink>
 
            </div>

            <div className="cu-section">

                {isLoading ? (
                    <div>Loading...</div>
                ) : disabledCUs.length > 0 ?

                    <table className="cu-table">
                        <thead>
                            <tr>
                                <th className='tableHeader--1'>Name</th>
                                <th className='tableHeader--2'>Logo</th>
                                <th className='tableHeader--7'>Manage</th>
                            </tr>
                        </thead>

                        <tbody>

                            {disabledCUs.map((cu) => {

                                return (
                                    <tr key={`cu--${cu.id}`}>
                                        <td>{cu.name}</td>
                                        <td><img style={{ height: '5rem', width: 'auto' }} src={cu.logoURL} /></td>

                                        <td>
                                            <div className='cu-buttons-container'>
                                                <button className='cu-button blue' id={`disableCU--${cu.id}`} tag={Link} onClick={(evt) => handleEnablePrompt(evt)}>Reenable</button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                    : <><h1>There are no Disabled Credit Unions to display.</h1></>
                }

                {showEnableModal && <EnableModal showEnableModal={showEnableModal} closeModal={() => setShowEnableModal(false)} handleEnableSubmit={handleEnableSubmit} />}
            </div>
        </>
    )
}