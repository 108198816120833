import React, { useState, useEffect } from 'react';
import { useSubtask } from '../../context/SubtaskContext';
import '../../styles/Modal.css';

const EditSubtaskModal = ({ show, onClose, onEditSubtask, subtaskToEdit, taskId }) => {
    const { subtaskName, subtaskDescription, subtaskDueDate, subtaskIsComplete, setSubtaskAndName } = useSubtask();
    const [updatedSubtaskName, setUpdatedSubtaskName] = useState('');
    const [updatedSubtaskDescription, setUpdatedSubtaskDescription] = useState('');
    const [updatedSubtaskDueDate, setUpdatedSubtaskDueDate] = useState('');
    const [updatedSubtaskIsComplete, setUpdatedSubtaskIsComplete] = useState(false);


    useEffect(() => {
        if (subtaskToEdit) {
            setUpdatedSubtaskName(subtaskToEdit.name);
            setUpdatedSubtaskDescription(subtaskToEdit.description);
            console.log(subtaskToEdit.dueDate);
            const formattedDate = subtaskToEdit.dueDate ? new Date(subtaskToEdit.dueDate).toISOString().split('T')[0] : '';
            setUpdatedSubtaskDueDate(formattedDate);
            setUpdatedSubtaskIsComplete(subtaskToEdit.isComplete);
        }
    }, [subtaskToEdit]);

    const handleSubmit = (event) => {
        event.preventDefault();
        onEditSubtask({ id: subtaskToEdit.id, name: updatedSubtaskName, description: updatedSubtaskDescription, dueDate: updatedSubtaskDueDate, isComplete: updatedSubtaskIsComplete, taskId: taskId });
        setUpdatedSubtaskName('');
        setUpdatedSubtaskDescription('');
        setUpdatedSubtaskDueDate('');
        setUpdatedSubtaskIsComplete(null);
        setSubtaskAndName({ id: subtaskToEdit.id, name: updatedSubtaskName, description: updatedSubtaskDescription, dueDate: updatedSubtaskDueDate, isComplete: updatedSubtaskIsComplete })
        onClose();
    };

    if (!show) return null;

    return (
        <div>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Edit Subtask</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <input
                            type="text"
                            value={updatedSubtaskName}
                            onChange={(e) => setUpdatedSubtaskName(e.target.value)}
                            required
                            className="modal-input"
                        />
                        <input
                            type="text"
                            value={updatedSubtaskDescription}
                            onChange={(e) => setUpdatedSubtaskDescription(e.target.value)}
                            required
                            className="modal-input"
                        />
                        <input
                            type="date"
                            value={updatedSubtaskDueDate}
                            onChange={(e) => setUpdatedSubtaskDueDate(e.target.value)}
                            required
                            className="modal-input"
                        />
                        <div className="complete-input">
                            <label htmlFor="complete">Complete</label>
                            <input
                                id="complete"
                                type="checkbox"
                                checked={updatedSubtaskIsComplete}
                                onChange={(e) => setUpdatedSubtaskIsComplete(e.target.checked)}
                                className="modal-input"
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditSubtaskModal;
