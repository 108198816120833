import { API_URL } from "../config";
import { LoginService } from "./LoginService";
import { handleExpiredToken, handleResponse, handleError } from "../helpers/ServicesHelper";

export const getAllRoles = () => {
    const userToken = LoginService.getCookie('token')
    return fetch(`${API_URL}/Roles`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
    }).then(handleExpiredToken).then(handleResponse).catch(handleError);
}

export const checkIfAdmin = () => {
    const userRole = LoginService.getCookie('role')
    if(userRole === "TTadmin"){
        return true
    }
    else{
        return false
    }
}

export const checkIfSalesOrAdmin = () => {
    const userRole = LoginService.getCookie('role')
    if(userRole === "TTadmin" || userRole === "TTsales"){
        return true
    }
    else{
        return false
    }
}

export const getRoleNamesFromNumbers = (ListOfUsers) => {
    ListOfUsers.forEach(user => {
        user.role = getRoleName(user.role);
    });
    return ListOfUsers;
}

export const getRoleNumbersFromNames = (ListOfUsers) => {
    ListOfUsers.forEach(user => {
        user.role = getRoleNumber(user.role);
    });
    return ListOfUsers;
}

const getRoleName = (roleID) => {
    if(roleID === 1){
        return "TTadmin"
    }
    else if(roleID === 2){
        return "TTsales"
    }
    else if(roleID === 3){
        return "CUuser"
    }
    else if(roleID === 4){
        return "DISABLED"
    }
    else{
        return 'INVALID ROLE'
    }
}

const getRoleNumber = (roleName) => {
    if(roleName === "TTadmin"){
        return 1
    }
    else if(roleName === "TTsales"){
        return 2
    }
    else if(roleName === "CUuser"){
        return 3
    }
    else if(roleName === "DISABLED"){
        return 4
    }
    else{
        return 'INVALID ROLE'
    }
}