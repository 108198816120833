import React, { createContext, useState, useContext, useEffect } from 'react';

const CuContext = createContext();

export const useCu = () => useContext(CuContext);

export const CuProvider = ({ children }) => {
    const [selectedCuId, setSelectedCuId] = useState();
    const [allCreditUnions, setAllCreditUnions] = useState([]);

    // On Refresh //
        // On refresh, this function can be used to check the selectedCuId key in session storage to reset the value of selectedCuId and maintain state in the component.
        const checkSessionStorageCuId = () => {
            setSelectedCuId(window.sessionStorage.getItem("selectedCuId"));
        }

    return (
        <CuContext.Provider value={{ selectedCuId, setSelectedCuId, allCreditUnions, setAllCreditUnions, checkSessionStorageCuId }}>
            {children}
        </CuContext.Provider>
    );
};

export default CuContext;
