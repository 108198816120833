import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { LoginService } from '../../services/LoginService';
import TTLogo from '../../assets/Logo.png'
import '../../styles/Auth.css'


export const ResetPassword = () => {

    const [queryParameters] = useSearchParams()
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [passwordOneState, setPasswordOne] = useState("");
    const [passwordTwoState, setPasswordTwo] = useState("");
    const [tokenState] = useState(queryParameters.get('token'));
    const [idState] = useState(queryParameters.get('id'));


    //states for password requirements
    const [initialRequirements, setInitialRequirements] = useState(true);

    const [passLengthMet, setPassLengthMet] = useState(false);
    const [passLowerMet, setPassLowerMet] = useState(false);
    const [passUpperMet, setPassUpperMet] = useState(false);
    const [passNumMet, setPassNumMet] = useState(false);
    const [passSpecialMet, setPassSpecialMet] = useState(false);
    const [matchingMet, setMatchingMet] = useState(false);

    const [credentialsGood, setCredentialsGood] = useState(false);


    //if not query param for token or state, navigate back to root/home
    useEffect(() => {
        if (!tokenState || !idState) {
            navigate('/')
        }
    }, []);



    const handleRequirements = (event) => {

        let P1, P2

        if (!loading && event.type === "change") {

            setLoading(true)

            let valueForRegexTests

            if (event.target.id === "passwordOne") {
                setPasswordOne(event.target.value);

                //if we're editing the FIRST password field, check regex requirements with entered value
                valueForRegexTests = event.target.value

                //if FIRST password field, set P1 to edited value, and P2 to state
                P1 = event.target.value
                P2 = passwordTwoState
            }

            else if (event.target.id === "passwordTwo") {
                setPasswordTwo(event.target.value)

                //if we're editing the SECOND password field, just check regex requirements with the state of the first field
                valueForRegexTests = passwordOneState

                //if SECOND password field, set P1 to edited value, and P2 to state
                P2 = event.target.value
                P1 = passwordOneState
            }

            // Regex for at least
            // 1 lowercase, 1 uppercase, 1 numeral, 1 special char (!@#$)
            const reLower = new RegExp("^(?=.*[a-z]).+$").test(valueForRegexTests);
            const reUpper = new RegExp("^(?=.*[A-Z]).+$").test(valueForRegexTests);
            const reNum = new RegExp("^(?=.*\\d).+$").test(valueForRegexTests);
            const reSpecial = new RegExp("(?=.*[!@#$]).+$").test(valueForRegexTests);
            const requiredLength = 8;
            const lengthMet = valueForRegexTests.length >= requiredLength;

            const passwordsMatch = ((passwordOneState.length > 0 && passwordTwoState.length > 0) && (P1 === P2));

            const requirementsMet = reLower && reUpper && reNum && reSpecial && lengthMet && passwordsMatch;

            //update states
            setPassLengthMet(lengthMet);
            setPassLowerMet(reLower);
            setPassUpperMet(reUpper);
            setPassNumMet(reNum);
            setPassSpecialMet(reSpecial);
            setMatchingMet(passwordsMatch);

            setInitialRequirements(false);
            setCredentialsGood(requirementsMet);

            setLoading(false);

        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        //if all password requirements met, continue
        if (credentialsGood) {

            const resetData = {
                token: tokenState,
                id: idState,
                newPassword: passwordOneState
            }

            await LoginService.handlePasswordChange(resetData)
                .then(resp => {
                    if (resp.status == "success") {
                        alert("Password successfully updated! Please login using new password.")
                    }
                })
                .catch(error => {
                    if (error.detail) {
                        alert(error.detail)
                    }
                    else {
                        alert('PASSWORD RESET FAILED. TRY AGAIN OR CONTACT AN ADMINISTRATOR')
                        console.error('PASSWORD RESET FAILED', error)
                    }
                });

            navigate('/')
        }

        else {
            alert("PLEASE MAKE SURE ALL REQUIREMENTS ARE MET")
        }
    }




    return (


        <main id="login" className="page">

            <img src={TTLogo} alt='logo' className='logo' />

            <section aligncontent="center" className="page-form">

                <form onSubmit={(evt) => handleSubmit(evt)} className="page-inner-form">
                    <h3 className="page-text">Update Password</h3>



                    {/* password requirements */}
                    <div className="pass-requirements">

                        <>

                            <p className='pass-requirements-header'>Passwords are case sensitive and must be:</p>
                            <ul className="pass-requirements-body">

                                <li className={
                                    initialRequirements
                                        ? "requirements-initial"
                                        : (passLengthMet ? "requirement-met" : "requirement-missing")
                                }>A minimum of eight (8) or more characters</li>
                                <li className={
                                    initialRequirements
                                        ? "requirements-initial"
                                        : (passUpperMet ? "requirement-met" : "requirement-missing")
                                }>Contain one (1) capital letter</li>
                                <li className={
                                    initialRequirements
                                        ? "requirements-initial"
                                        : (passLowerMet ? "requirement-met" : "requirement-missing")
                                }>Contain one (1) lower case letter</li>
                                <li className={
                                    initialRequirements
                                        ? "requirements-initial"
                                        : (passNumMet ? "requirement-met" : "requirement-missing")
                                }>Contain one (1) number</li>
                                <li className={
                                    initialRequirements
                                        ? "requirements-initial"
                                        : (passSpecialMet ? "requirement-met" : "requirement-missing")
                                }>Contain one (1) special character (!@#$)</li>
                                <li className={
                                    initialRequirements
                                        ? "requirements-initial"
                                        : (matchingMet ? "requirement-met" : "requirement-missing")
                                }>Both Passwords must Match</li>
                            </ul>

                        </>

                    </div>




                    <label htmlFor="passwordOne" className="page-text">New Password</label>
                    <input
                        autoFocus="on"
                        type='password'
                        onChange={e => handleRequirements(e)}
                        name="passwordOne"
                        id="passwordOne"
                        value={passwordOneState}
                        autoComplete="off"
                        className="page-input"
                    />

                    <label htmlFor="passwordTwo" className="page-text">Confirm New Password</label>
                    <input
                        type='password'
                        onChange={e => handleRequirements(e)}
                        name="passwordTwo"
                        id="passwordTwo"
                        value={passwordTwoState}
                        autoComplete="off"
                        className="page-input"
                    />

                    <button
                        className="page-button"
                        type="submit">
                        Submit
                    </button>

                </form>


            </section>
        </main>
    );
}