import React, { createContext, useState, useContext } from 'react';
import { LoginService, setCookie } from '../services/LoginService';
import { checkIfAdmin, checkIfSalesOrAdmin } from '../services/RoleService';
import { LogoutModal } from '../components/common/LogoutModal';

const UserContext = createContext();
export const PassUserProvider = {}

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {

    const checkIfLoggedIn = () => {
        return (LoginService.getCookie('token') !== null) ? true : false
    }

    const [isAdmin, setIsAdmin] = useState(checkIfAdmin());
    const [isSalesOrAdmin, setIsSalesOrAdmin] = useState(checkIfSalesOrAdmin());
    const [isLoggedIn, setIsLoggedIn] = useState(checkIfLoggedIn());
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const handleLogout = async () => {

        await setCookie("token", "", -1);
        await setCookie("name", "", -1);
        await setCookie("email", "", -1);
        await setCookie("role", "", -1);
        await setCookie("lastActiveTime", "", -1);

        await setIsLoggedIn(false)
        await setIsAdmin(false)
        await setIsSalesOrAdmin(false)

        await setShowLogoutModal(true)

    }

    PassUserProvider.handleLogout = handleLogout
    PassUserProvider.setShowLogoutModal = setShowLogoutModal

    return (
        <UserContext.Provider value={{ isAdmin, setIsAdmin, isSalesOrAdmin, setIsSalesOrAdmin, isLoggedIn, setIsLoggedIn, handleLogout, showLogoutModal, setShowLogoutModal}}>
            {showLogoutModal && <LogoutModal showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal} />}
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;