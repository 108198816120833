import React, { useState, useEffect } from 'react';
import { useTask } from '../../context/TaskContext';
import '../../styles/Modal.css';

const EditTaskModal = ({ show, onClose, onEditTask }) => {
    const { selectedTaskData } = useTask();
    const [taskName, setTaskName] = useState('');
    const [taskDescription, setTaskDescription] = useState('');

    useEffect(() => {
        setTaskName(selectedTaskData.name);
        setTaskDescription(selectedTaskData.description);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        onEditTask({ id: selectedTaskData.id, name: taskName, description: taskDescription, groupId: selectedTaskData.groupId });
        onClose();
    };

    if (!show) return null;

    return (
        <div>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Edit Task</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <input
                            type="text"
                            value={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                            className="modal-input"
                            required
                        />
                        <input
                            type="text"
                            value={taskDescription}
                            onChange={(e) => setTaskDescription(e.target.value)}
                            required
                            className="modal-input"
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditTaskModal;
