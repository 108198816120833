import React from 'react';
import '../../styles/Modal.css';

export const EnableModal = ({ showEnableModal, closeModal, handleEnableSubmit }) => {
    
    if (!showEnableModal) return null;

    return (

        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Enable Credit Union</h3>
                <p>Are you sure you want to re-enable this Credit Union?</p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={closeModal}>Cancel</button>
                    <button className="btn-modal" onClick={handleEnableSubmit}>Enable</button>
                </div>
            </div>
        </>

    );
};