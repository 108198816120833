import React from 'react';
import '../../styles/Modal.css';

const DeleteTaskModal = ({ show, onClose, onConfirmDelete, selectedTaskId }) => {

    if (!show) return null;

    const handleConfirmDelete = () => {
        if (!selectedTaskId) {
            alert('No task selected for deletion');
            return;
        }
        onConfirmDelete(selectedTaskId);
        onClose();
    };

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Delete Task</h3>
                <p>Deleting this task will also delete all tasks and subtasks assigned to it. Are you sure?</p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={onClose}>Cancel</button>
                    <button className="btn-modal" onClick={handleConfirmDelete}>Delete</button>
                </div>
            </div>
        </>
    );
};

export default DeleteTaskModal;
