import { API_URL } from "../config";
import { handleResponse, handleError } from "../helpers/ServicesHelper";
import { PassUserProvider } from "../context/UserContext";

//accepts cookie name, value, and expiration in minutes
export const setCookie = (cname, cvalue, expSeconds) => {
    const d = new Date();
    d.setTime(d.getTime() + (expSeconds * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const LoginService = {
    //accepts username and pass entered by user on login page
    handleLogin: async (userCredentials) => {
        try {
            const response = await fetch(`${API_URL}/Users/Login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userCredentials),
            });

            // Check if the response is successful, if not throw an error with details
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || "Unknown login error occurred.");
            }

            return handleResponse(response);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    //accepts email entered by user on forgot password page
    handleForgotPassword: async (userEmail) => {
        try {
            const response = await fetch(`${API_URL}/users/forgotPassword/?email=${userEmail}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    },

    //accepts email entered by user on forgot username page
    handleForgotUsername: async (userEmail) => {
        try {
            const response = await fetch(`${API_URL}/users/forgotUsername/?email=${userEmail}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    },

    //accepts resetData created in ResetPassword
    handlePasswordChange: async (resetData) => {
        try {
            const response = await fetch(`${API_URL}/Users/resetPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(resetData),
            });

            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    },

    // Reset failed login attempts for the user
    resetFailedLoginAttempts: async (email) => {
        try {
            const response = await fetch(`${API_URL}/users/resetFailedLoginAttempts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    },

    //Accepts the user data/token from the API and saves them as cookies
    saveNewUser: (userDataAndToken) => {
        document.cookie = "token=" + userDataAndToken.access_token + ";path=/";
        document.cookie = "name=" + userDataAndToken.username + ";path=/";
        document.cookie = "email=" + userDataAndToken.email + ";path=/";
        document.cookie = "role=" + userDataAndToken.role + ";path=/";
        document.cookie = "id=" + userDataAndToken.id + ";path=/";
        document.cookie = "lastActiveTime=" + new Date() + ";path=/";
    },

    checkLoginRequirements: (enteredUsername, enteredPassword) => {
        let errorMessage = "";
        let canLogin = true;

        if (enteredUsername === "") {
            errorMessage = "Please enter a username";
            canLogin = false;
        }

        if (enteredPassword === "") {
            errorMessage = "Please enter a password";
            canLogin = false;
        }

        if (enteredUsername === "" && enteredPassword === "") {
            errorMessage = "Please enter a username and password";
            canLogin = false;
        }

        const results = {
            "canLogin": canLogin,
            "errorMessage": errorMessage,
        };

        return results;
    },

    getCookie: (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    },

    //Resets the stored time of when the user was last active
    updateLastActive: () => {
        const currentToken = LoginService.getCookie("token");
        if (currentToken) {
            document.cookie = "lastActiveTime=" + new Date() + ";path=/";
        }
    },

    //Checks current time against time that the user was last active and when they logged in. Converts these to minutes.
    checkInactivity: () => {
        const MAX_IDLE_MINUTES = 30;
        const currentToken = LoginService.getCookie("token");
        if (currentToken) {
            const currentTime = new Date().getTime() / 1000;
            const lastActiveTime = new Date(LoginService.getCookie("lastActiveTime")).getTime() / 1000;

            const timeInactive = (currentTime - lastActiveTime) / 60;

            //If it's been longer than 30 minutes, Logout the user and inform them their session has expired
            if (timeInactive >= MAX_IDLE_MINUTES) {
                PassUserProvider.handleLogout();
            }
        }
    },
};
