import '../../styles/Modal.css';

export const EditNameModal = ({ showEditModal, closeModal, handleEditName, CUToEdit, setCUToEdit }) => {


    const handleSubmit = (event) => {
        event.preventDefault();
        handleEditName(CUToEdit)
        closeModal();
    };

    if (!showEditModal) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Edit Credit Union Name </h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">

                        <input
                            id='name-input'
                            type="text"
                            value={CUToEdit.name}
                            onChange={
                                (event) => {
                                    const copy = { ...CUToEdit }
                                    copy.name = event.target.value
                                    setCUToEdit(copy)
                                }
                            }
                            placeholder="Credit Union Name"
                            required
                            className="modal-input"
                        />





                        </div>

                        <div className="modal-footer">
                            <button type="submit" className="btn-modal">Save</button>
                            <button type="button" className="btn-modal" onClick={closeModal}>Cancel</button>
                        </div>

                </form>

            </div>
        </>
    );
};