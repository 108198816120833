import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useGroup } from '../../context/GroupContext';
import { useTask } from '../../context/TaskContext';
import TaskService from '../../services/TaskService';
import SubtaskService from '../../services/SubtaskService';
import AddTaskModal from './AddTaskModal';
import DeleteTaskModal from './DeleteTaskModal';
import EditTaskModal from './EditTaskModal';
import SubtasksView from '../subtasks/SubtasksView';
import '../../styles/TasksView.css';
import { useUserContext } from '../../context/UserContext';

const TasksView = () => {
    // State, Params, Context. //
    // noTasksMessage: Displayed by conditional if no task is available.
    const noTasksMessage = "No tasks available. Add a new task to get started.";

    //isSalesOrAdmin Context
    const { isSalesOrAdmin } = useUserContext();

    // groupId: Watched by useEffect to getTasksByGroupId and update setTasks state, which is used to map tasks and create task cards. Also, used by handleAddTask to specify groupId.
    const { groupId, taskId, subtaskId } = useParams();

    // groupName: Used to display the group name above the task cards.
    const { groupName, setGroupName } = useGroup();

    // setSelectedTaskData: Establishes state of selectedTaskData. Used by selectTask to set state for display in subtasks, promptEditTask to set state for EditTaskModal, and handleEditTask to call the api to update the task in the database.
    // selectedTaskData: selectedTaskData.id is used to set which task and it's subtasks should be deleted in handleDeleteTaskAndSubtasks. If selectedTaskData exists, then subtasks are rendered below the task cards.
    // deleteTaskAndSubtasks: Used by handleDeleteTaskAndSubtasks. Deletes Subtasks from the database one-by-one, then deletes the Task from the database.
    const { selectedTaskData, setSelectedTaskData, deleteTaskAndSubtasks } = useTask();

    // setTasks: Used by fetchTasks to set tasks state as the response from getTasksByGroupId. 
    // tasks: Watched in the return and mapped out into task cards, if they exist.
    const [tasks, setTasks] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState();
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [selectedSubtaskId, setSelectedSubtaskId] = useState();
    const [openSubtasks, setOpenSubtasks] = useState(false);
    const [percentage, setPercentage] = useState();
    const [tasksWithSubtasks, setTasksWithSubtasks] = useState([]);
    const [totalTasks, setTotalTasks] = useState(0);
    const [completedTasks, setCompletedTasks] = useState(0);
    const [remainingTasks, setRemainingTasks] = useState(0);
    const [subtaskCompletionPercentage, setSubtaskCompletionPercentage] = useState();

    // useEffects //
    useEffect(() => {
        const storedGroup = JSON.parse(localStorage.getItem('selectedGroup'));
        const storedTask = JSON.parse(localStorage.getItem('selectedTask'));

        if (storedGroup) {
            setGroupName(storedGroup.name);
        }
        if (storedTask) {
            setSelectedTaskData(storedTask);
            setSelectedTaskId(storedTask.id);
        }
    }, []);

    useEffect(() => {
        if (groupId) {
            setSelectedGroupId(groupId);
        }
        if (taskId) {
            setSelectedSubtaskId(subtaskId);
        }
        if (subtaskId) {
            setSelectedTaskId(taskId);
        }
    }, [groupId, taskId, selectedSubtaskId]);

    useEffect(() => {
        if (selectedGroupId) {
            localStorage.setItem('selectedGroupId', selectedGroupId);
        }
        if (selectedTaskId) {
            localStorage.setItem('selectedTaskId', selectedTaskId);
        }
        if (selectedSubtaskId) {
            localStorage.setItem('selectedSubtaskId', selectedSubtaskId);
        }
    }, [selectedGroupId, selectedTaskId, selectedSubtaskId])

    useEffect(() => {
        if (selectedSubtaskId) {
            fetchTasks();
            setOpenSubtasks(!openSubtasks);
        } else {
            fetchTasks();
        }
    }, [selectedGroupId, selectedTaskId, selectedSubtaskId]);

    useEffect(() => {
        if (tasks.length > 0 && selectedTaskId) {
            const selectedTask = tasks.find(task => task.id === selectedTaskId);
            if (selectedTask) {
                selectTask(selectedTask);
            }
        }
    }, [tasks, selectedTaskId]);

    useEffect(() => {
        setPercentage(calculateOverallCompletionPercentage());
        updateTaskCounts(tasksWithSubtasks);
    }, [tasksWithSubtasks]);

    useEffect(() => {
        fetchTasks();
    }, [subtaskCompletionPercentage]);

    const fetchTasks = async () => {
        setIsLoading(true);
        try {
            const response = await TaskService.getTasksByGroupId(selectedGroupId);
            setTasks(response);
            setTotalTasks(response.length);
            await fetchTasksWithSubtasks(response);
        } catch (error) {
            console.error('Fetching tasks failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTasksWithSubtasks = async (tasks) => {
        let tasksWithSubs = await Promise.all(tasks.map(async (task) => {
            const subtasks = await SubtaskService.getSubtasksByTaskId(task.id);
            return { ...task, isComplete: subtasks.every(subtask => subtask.isComplete), subtasks };
        }));
        setTasksWithSubtasks(tasksWithSubs);
    };

    const handleAddTask = async (taskData) => {
        try {
            await TaskService.createTask({ ...taskData, groupId: selectedGroupId });
            fetchTasks();
        } catch (error) {
            console.error('Creating task failed', error);
        }
        setShowAddModal(false);
    };

    const handleEditTask = async (taskData) => {
        try {
            await TaskService.updateTask(taskData.id, taskData);
            setSelectedTaskData(taskData);
            fetchTasks();
        } catch (error) {
            console.error('Editing task failed', error);
        }
        setShowEditModal(false);
    };

    const handleDeleteTaskAndSubtasks = async () => {
        await TaskService.deleteTask(selectedTaskId)
        setOpenSubtasks(false)
        fetchTasks();
    };

    const promptEditTask = async (task) => {
        selectTask(task);
        setShowEditModal(true);
    };

    const promptDeleteTask = (taskID) => {
        setSelectedTaskId(taskID)
        setShowDeleteModal(true);
    };

    const selectTask = (task) => {
        if (openSubtasks === false) {
            setOpenSubtasks(!openSubtasks);
            setSelectedTaskData(task);
            setSelectedTaskId(task.id);
        } else {
            setSelectedTaskData(task);
            setSelectedTaskId(task.id);
        }

        // Update selected task in localStorage
        setSelectedTaskData(task);
        localStorage.setItem('selectedTask', JSON.stringify(task));
        localStorage.setItem('selectedTaskId', JSON.stringify(task.id));
    };

    const updateTaskCounts = (tasks) => {
        const completedCount = tasks.filter(task => task.isComplete).length;
        setCompletedTasks(completedCount);
        setRemainingTasks(tasks.length - completedCount);
    };

    const calculateOverallCompletionPercentage = () => {
        const totalTasks = tasksWithSubtasks.length;
        const completedTasks = tasksWithSubtasks.filter(task => task.isComplete).length;
        return totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;
    };

    const remainingPercentage = 100 - percentage;
    const outerCircleStyle = {
        background: `conic-gradient(
            blue ${percentage * 3.6001}deg,
            grey ${percentage * 3.6}deg ${remainingPercentage * 3.6 + percentage * 3.6}deg
        )`
    };

    // Return //
    return (
        <div>
            <div className="tasks-header-container">
                <div className="tasks-header">
                    <h2>{groupName ? `${groupName} Tasks` : 'Tasks'}</h2>
                    <div className="tasks-completion-percentage">
                        <p>Tasks Completed</p>
                        <div className="progress-container">
                            <div className="progress-circle" style={outerCircleStyle}>
                                <div className="inner-circle">
                                    <div className="percentage">
                                        {percentage}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tasks-totals">
                        <div className="tasks-total-tasks">
                            <h4>Tasks: {totalTasks}</h4>
                        </div>
                        <div className={`tasks-remaining ${remainingTasks > 0 ? 'remaining-tasks' : ''}`}>
                            <h4>Remaining: {remainingTasks}</h4>
                        </div>
                        <div className={`tasks-completed ${completedTasks > 0 ? 'completed-tasks' : ''}`}>
                            <h4>Completed: {completedTasks}</h4>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <div>Loading...</div>
            ) : tasks.length > 0 ? (
                <div className="task-grid">
                    {tasks.map(task => {
                        const subtasks = tasksWithSubtasks.find(t => t.id === task.id)?.subtasks || [];
                        const completedSubtasksCount = subtasks.filter(subtask => subtask.isComplete).length;
                        const stCompletionPercentage = subtasks.length > 0
                            ? `${completedSubtasksCount} / ${subtasks.length}`
                            : 'No subtasks';


                        return (
                            <NavLink
                                className={`task-name ${selectedTaskId === task.id ? 'active' : ''}`}
                                to={`/tasks/${groupId}/${task.id}`}
                                onClick={() => selectTask(task)}
                            >
                                <div key={task.id} className={`task-card`}>
                                    <h2>{task.name}</h2>
                                    <p className={'task-description'}>{task.description}</p>
                                    <p className={'tasks-card-subtasks-completed'}>Subtasks Completed: {stCompletionPercentage}</p>

                                    {/* Edit and Delete buttons visible only if user's role TTadmin or TTsales */}
                                    {isSalesOrAdmin ? <>
                                        <button className="edit-button" onClick={() => promptEditTask(task)}>✏️</button>
                                        <button className="delete-button" onClick={() => promptDeleteTask(task.id)}>X</button>
                                    </>
                                        : <></>
                                    }


                                </div>
                            </NavLink>
                        );
                    })}
                </div>
            ) : (
                <p>{noTasksMessage}</p>
            )}

            {/* Add Task button only visible if user's role TTadmin or TTsales */}
            {isSalesOrAdmin ? 
                <button onClick={() => setShowAddModal(true)} className="add-task-button">➕ Add Task</button>
                : <div className="blank-space"></div>
            }

            {openSubtasks && (
                <SubtasksView
                    openSubtasks={openSubtasks}
                    setOpenSubtasks={setOpenSubtasks}
                    selectedSubtaskId={selectedSubtaskId}
                    subtaskCompletionPercentage={subtaskCompletionPercentage}
                    setSubtaskCompletionPercentage={setSubtaskCompletionPercentage}
                />
            )}

            {showAddModal && <AddTaskModal show={showAddModal} onClose={() => setShowAddModal(false)} onAddTask={handleAddTask} />}
            {showEditModal && <EditTaskModal show={showEditModal} onClose={() => setShowEditModal(false)} onEditTask={handleEditTask} />}
            {showDeleteModal && <DeleteTaskModal show={showDeleteModal} onClose={() => setShowDeleteModal(false)} onConfirmDelete={handleDeleteTaskAndSubtasks} selectedTaskId={selectedTaskId} />}            
        </div>
    );
};

export default TasksView;
