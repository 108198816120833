import { useState } from 'react';
import '../../styles/Modal.css';

export const EditLogoModal = ({ showEditModal, closeModal, handleEditLogo, newLogo, setNewLogo }) => {

    const [newLogoName, setNewLogoName] = useState();

    const handleSubmit = (event) => {
        event.preventDefault();
        handleEditLogo()
        closeModal();
    };

    if (!showEditModal) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Upload New Credit Union Logo</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <input
                            type="file"
                            value={newLogo.fileName ? newLogo.fileName : undefined}
                            onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    setNewLogo(e.target.files[0])
                                }
                                else(
                                    setNewLogo([])
                                )
                            }}
                            required
                            className="modal-input"
                        />

                    </div>

                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={closeModal}>Cancel</button>
                    </div>

                </form>

            </div>
        </>
    );
};