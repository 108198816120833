import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MeetingService from '../../services/MeetingService';
import { useCu } from '../../context/CuContext';
import { useUserContext } from '../../context/UserContext';
import { AttachmentService } from '../../services/AttachmentService';
import { AddAttachmentModal } from '../common/AddAttachmentModal';
import { ViewAttachmentModal } from '../common/ViewAttachmentsModal';
import { DeleteAttachmentModal } from '../common/DeleteAttachmentModal';
import { MeetingNotesEmailModal } from './MeetingNotesEmailModal';
import '../../styles/MeetingNotes.css';

const MeetingNotes = () => {
//// State Management ////
    /// useParams ///
    // meetingID
    const { meetingID } = useParams();
    /// useContext ///
    // cuId = selectedCuId
    const { selectedCuId } = useCu();
    // UserContext
    const { isSalesOrAdmin } = useUserContext();
    /// useStates ///
    //  Text States - Text to Display States
        /*
            - State is set on mount of the component, if there is existing data.
            - State is set when a user saves changes made in the textarea, via handleSubmit functions.
        */
    const [topicsText, setTopicsText] = useState();
    const [dateTime, setDateTime] = useState();
    const [groupsText, setGroupsText] = useState();
    const [agendaText, setAgendaText] = useState();
    const [actionItemsText, setActionItemsText] = useState();
    const [discussionNotesText, setDiscussionNotesText] = useState();
    const [decisionsMadeText, setDecisionsMadeText] = useState();
    //  isEditing - Boolean Value States
        /*
            - State is set to true when the user selects the textarea and the text is altered to be different than the state of agendaText.
            - If the state is true, then anytime the textarea text is restored to the state of agendaText, the state will be restored to false.
        */
    const [isEditingAgenda, setIsEditingAgenda] = useState(false);
    const [isEditingActionItems, setIsEditingActionItems] = useState(false);
    const [isEditingDiscussionNotes, setIsEditingDiscussionNotes] = useState(false);
    const [isEditingDecisionsMade, setIsEditingDecisionsMade] = useState(false);
    //  updatingText - onChange of Textarea States
        /*
            - State is set onChange when a user has selected the correlated textarea and the text is being altered.
        */
    const [updatingAgendaText, setUpdatingAgendaText] = useState();
    const [updatingActionItemsText, setUpdatingActionItemsText] = useState();
    const [updatingDiscussionNotesText, setUpdatingDiscussionNotesText] = useState();
    const [updatingDecisionsMadeText, setUpdatingDecisionsMadeText] = useState();
    //  deleteText - boolean to trigger deletion of a textarea
    const [deleteAgendaText, setDeleteAgendaText] = useState(false);
    const [deleteActionItemsText, setDeleteActionItemsText] = useState(false);
    const [deleteDiscussionNotesText, setDeleteDiscussionNotesText] = useState(false);
    const [deleteDecisionsMadeText, setDeleteDecisionsMadeText] = useState(false);

    const [agendaSaveButtonText, setAgendaSaveButtonText] = useState('Save');
    const [actionItemsSaveButtonText, setActionItemsSaveButtonText] = useState('Save');
    const [discussionNotesSaveButtonText, setDiscussionNotesSaveButtonText] = useState('Save');
    const [decisionsMadeSaveButtonText, setDecisionsMadeSaveButtonText] = useState('Save');
    //  attachments
    const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false);
    const [showViewAttachmentsModal, setShowViewAttachmentsModal] = useState(false);
    const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [attachmentToDelete, setAttachmentToDelete] = useState();
    //  email
    const [showEmailModal, setShowEmailModal] = useState(false);

//// Meeting Note Object ////
    // original state of date
    const [originalDate, setOriginalDate] = useState();
    // Should be set when fetchMeetingDataByMeetingID() is called, and adjusted whenever there is a change submitted before calling MeetingService.updateMeeting(id, MeetingNote)
    const meetingNote = {
        "id": meetingID,
        "date": originalDate,
        "topics": topicsText,
        "agenda": agendaText,
        "discussionNotes": discussionNotesText,
        "actionItems": actionItemsText,
        "decisionsMade": decisionsMadeText,
        "cuId": 1
    }


//// useEffects ////
    //  Initialize Data
    useEffect(() => {
        fetchMeetingDataByMeetingID();
        fetchMeetingGroupsByMeetingID();
    }, [meetingID])

    //  Agenda Text Change Detection
    useEffect(() => {
        const hasChanges = updatingAgendaText !== undefined && updatingAgendaText !== agendaText;
        setIsEditingAgenda(hasChanges); // Update isEditingAgenda based on actual text changes.
        setAgendaSaveButtonText(hasChanges ? 'Save Changes' : 'Save');
    }, [updatingAgendaText, agendaText]);

    //  Action Items Text Change Detection
    useEffect(() => {
        const hasChanges = updatingActionItemsText !== undefined && updatingActionItemsText !== actionItemsText;
        setIsEditingActionItems(hasChanges); // Update isEditingActionItems based on actual text changes.
        setActionItemsSaveButtonText(hasChanges ? 'Save Changes' : 'Save');
    }, [updatingActionItemsText, actionItemsText]);

    //  Discussion Notes Text Change Detection
    useEffect(() => {
        const hasChanges = updatingDiscussionNotesText !== undefined && updatingDiscussionNotesText !== discussionNotesText;
        setIsEditingDiscussionNotes(hasChanges); // Update isEditingDiscussionNotes based on actual text changes.
        setDiscussionNotesSaveButtonText(hasChanges ? 'Save Changes' : 'Save');
    }, [updatingDiscussionNotesText, discussionNotesText]);

    //  Decisions Made Text Change Detection
    useEffect(() => {
        const hasChanges = updatingDecisionsMadeText !== undefined && updatingDecisionsMadeText !== decisionsMadeText;
        setIsEditingDecisionsMade(hasChanges); // Update isEditingDecisionsMade based on actual text changes.
        setDecisionsMadeSaveButtonText(hasChanges ? 'Save Changes' : 'Save');
    }, [updatingDecisionsMadeText, decisionsMadeText]);

    //  Trigger handleDeleteAgenda
    useEffect(() => {
        if (deleteAgendaText === true && agendaText === '') {
            handleDeleteAgenda();
        }
    }, [deleteAgendaText, agendaText]);

    //  Trigger handleDeleteActionItems
    useEffect(() => {
        if (deleteActionItemsText === true && actionItemsText === '') {
            handleDeleteActionItems();
        }
    }, [deleteActionItemsText, actionItemsText]);

    //  Trigger handleDeleteDiscussionNotes
    useEffect(() => {
        if (deleteDiscussionNotesText === true && discussionNotesText === '') {
            handleDeleteDiscussionNotes();
        }
    }, [deleteDiscussionNotesText, discussionNotesText]);

    //  Trigger handleDeleteDecisionsMade
    useEffect(() => {
        if (deleteDecisionsMadeText === true && decisionsMadeText === '') {
            handleDeleteDecisionsMade();
        }
    }, [deleteDecisionsMadeText, decisionsMadeText]);

//// Fetches ////
    // Meeting Data by meetingID
    const fetchMeetingDataByMeetingID = async () => {
        const response = await MeetingService.getMeetingByMeetingId(meetingID);
        setTopicsText(response.topics ? response.topics : '');
        setAgendaText(response.agenda ? response.agenda : '');
        setActionItemsText(response.actionItems ? response.actionItems : '');
        setDiscussionNotesText(response.discussionNotes ? response.discussionNotes : '');
        setDecisionsMadeText(response.decisionsMade ? response.decisionsMade : '');
        setOriginalDate(response.date);
        formatDateTime(response.date);
    };

    // Meeting Groups by meetingID
    const fetchMeetingGroupsByMeetingID = async () => {
        const response = await MeetingService.getGroupsByMeetingID(meetingID);
        const joinedGroups = response.map(group => group.name).join(", ");
        setGroupsText(joinedGroups);
    }

//// Formatting ////
    // Date & Time
    const formatDateTime = (dateString) => {
        // Create a Date object from the dateString.
        const date = new Date(dateString);

        // This will create a new Date object adjusted to the client's local time.
        // It compensates for the timezone offset between UTC and the local timezone.
        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

        // Format the adjusted Date object.
        const formattedDateTime = localDate.toLocaleString(undefined, {
            year: 'numeric', // Include the year in the output.
            month: 'short', // Example: "Nov"
            day: 'numeric', // Example: "4"
            hour: '2-digit', // Example: "11" or "23"
            minute: '2-digit' // Example: "59"
        });

        setDateTime(formattedDateTime);
    }

//// Handlers ////
    // handleCancel //
        // Agenda
    const handleCancelAgenda = () => {
        /* Reset the updating text to the last saved state, effectively discarding any changes. */
        setUpdatingAgendaText(agendaText);
        /* Optionally, you can also reset the isEditing state if you're using it to control UI elements. */
        setIsEditingAgenda(false);
    };
        // Action Items
        /* Logic is the same as that of handleCancelAgenda comments */
    const handleCancelActionItems = () => {
        setUpdatingActionItemsText(actionItemsText);
        setIsEditingActionItems(false);
    };
        // Discussion Notes
        /* Logic is the same as that of handleCancelAgenda comments */
    const handleCancelDiscussionNotes = () => {
        setUpdatingDiscussionNotesText(discussionNotesText);
        setIsEditingDiscussionNotes(false);
    };
        // DecisionsMade
        /* Logic is the same as that of handleCancelAgenda comments */
    const handleCancelDecisionsMade = () => {
        setUpdatingDecisionsMadeText(decisionsMadeText);
        setIsEditingDecisionsMade(false);
    };

    // handleSubmit //
        // Agenda
    const handleSubmitAgenda = async (e) => {
        e.preventDefault();
        const updatedMeetingNote = { ...meetingNote, agenda: updatingAgendaText };
        try {
            await MeetingService.updateMeeting(meetingID, updatedMeetingNote);
            console.log('Meeting note updated successfully.');
            setAgendaText(updatingAgendaText);
            setIsEditingAgenda(false);
        } catch (error) {
            console.error('Error updating meeting note:', error);
        }
    };
        // Action Items
    const handleSubmitActionItems = async (e) => {
        e.preventDefault();
        const updatedMeetingNote = { ...meetingNote, actionItems: updatingActionItemsText };
        try {
            await MeetingService.updateMeeting(meetingID, updatedMeetingNote);
            console.log('Meeting note updated successfully.');
            setActionItemsText(updatingActionItemsText);
            setIsEditingActionItems(false);
        } catch (error) {
            console.error('Error updating meeting note:', error);
        }
    };
        // Discussion Notes
    const handleSubmitDiscussionNotes = async (e) => {
        e.preventDefault();
        const updatedMeetingNote = { ...meetingNote, discussionNotes: updatingDiscussionNotesText };
        try {
            await MeetingService.updateMeeting(meetingID, updatedMeetingNote);
            console.log('Meeting note updated successfully.');
            setDiscussionNotesText(updatingDiscussionNotesText);
            setIsEditingDiscussionNotes(false);
        } catch (error) {
            console.error('Error updating meeting note:', error);
        }
    };
        // Decisions Made
    const handleSubmitDecisionsMade = async (e) => {
        e.preventDefault();
        const updatedMeetingNote = { ...meetingNote, decisionsMade: updatingDecisionsMadeText };
        try {
            await MeetingService.updateMeeting(meetingID, updatedMeetingNote);
            console.log('Meeting note updated successfully.');
            setDecisionsMadeText(updatingDecisionsMadeText);
            setIsEditingDecisionsMade(false);
        } catch (error) {
            console.error('Error updating meeting note:', error);
        }
    };

    // promptDelete //
        // Prompt Delete Agenda
    const promptDeleteAgenda = () => {
        // Confirm deletion with the user
        const isConfirmed = window.confirm('Are you sure you want to delete the agenda text?');
        if (isConfirmed) {
            setAgendaText('');
            setDeleteAgendaText(true);
        }
    }
        // Prompt Delete ActionItems
    const promptDeleteActionItems = () => {
        // Confirm deletion with the user
        const isConfirmed = window.confirm('Are you sure you want to delete the action items text?');
        if (isConfirmed) {
            setActionItemsText('');
            setDeleteActionItemsText(true);
        }
    }
        // Prompt Delete DiscussionNotes
    const promptDeleteDiscussionNotes = () => {
        // Confirm deletion with the user
        const isConfirmed = window.confirm('Are you sure you want to delete the discussion notes text?');
        if (isConfirmed) {
            setDiscussionNotesText('');
            setDeleteDiscussionNotesText(true);
        }
    }
        // Prompt Delete DecisionsMade
    const promptDeleteDecisionsMade = () => {
        // Confirm deletion with the user
        const isConfirmed = window.confirm('Are you sure you want to delete the decisions made text?');
        if (isConfirmed) {
            setDecisionsMadeText('');
            setDeleteDecisionsMadeText(true);
        }
    }

    // handleDelete //
        // Delete Agenda
    const handleDeleteAgenda = async () => {
        const updatedMeetingNote = { ...meetingNote, agenda: agendaText };
        try {
            await MeetingService.updateMeeting(meetingID, updatedMeetingNote);
            console.log('Meeting note updated successfully.');
            setIsEditingAgenda(false);
            setDeleteAgendaText(false);
        } catch (error) {
            console.error('Error updating meeting note:', error);
        }
    };
        // Delete ActionItems
    const handleDeleteActionItems = async () => {
        const updatedMeetingNote = { ...meetingNote, actionItems: actionItemsText };
        try {
            await MeetingService.updateMeeting(meetingID, updatedMeetingNote);
            console.log('Meeting note updated successfully.');
            setIsEditingActionItems(false);
            setDeleteActionItemsText(false);
        } catch (error) {
            console.error('Error updating meeting note:', error);
        }
    };
        // Delete DiscussionNotes
    const handleDeleteDiscussionNotes = async () => {
        const updatedMeetingNote = { ...meetingNote, discussionNotes: discussionNotesText };
        try {
            await MeetingService.updateMeeting(meetingID, updatedMeetingNote);
            console.log('Meeting note updated successfully.');
            setIsEditingDiscussionNotes(false);
            setDeleteDiscussionNotesText(false);
        } catch (error) {
            console.error('Error updating meeting note:', error);
        }
    };
        // Delete DecisionsMade
    const handleDeleteDecisionsMade = async () => {
        const updatedMeetingNote = { ...meetingNote, decisionsMade: decisionsMadeText };
        try {
            await MeetingService.updateMeeting(meetingID, updatedMeetingNote);
            console.log('Meeting note updated successfully.');
            setIsEditingDecisionsMade(false);
            setDeleteDecisionsMadeText(false);
        } catch (error) {
            console.error('Error updating meeting note:', error);
        }
    };

    // Handle Attachments //
    const promptAddAttachment = () => {
        setShowAddAttachmentModal(true);
    };

    const promptViewAttachments = async () => {
        try {
            const response = await AttachmentService.getAttachmentsByMeeting(meetingID);
            setAttachments(response);
            setShowViewAttachmentsModal(true);
        } catch (error) {
            console.error('Failed to fetch attachments', error);
        }
    };

    const promptDeleteAttachment = (attachment) => {
        setAttachmentToDelete(attachment);
        setShowViewAttachmentsModal(false);
        setShowDeleteAttachmentModal(true);
    };

    const handleDownloadAttachment = async (attachmentKey, attachmentName) => {
        try {
            await AttachmentService.downloadAttachment(attachmentKey, attachmentName);
        } catch (error) {
            console.error('Failed to download attachment', error);
        }
        setShowAddAttachmentModal(false);
    };

    const handleAddAttachment = async (formData) => {
        try {
            await AttachmentService.uploadAttachment(formData, meetingID);
            promptViewAttachments(); // Refresh attachments list
        } catch (error) {
            console.error('Failed to upload attachment', error);
        }
        setShowAddAttachmentModal(false);
    };

    const handleDeleteAttachment = async (id) => {
        try {
            //delete attachment from DB and Bucket
            await AttachmentService.deleteAttachment(id);
        } catch (error) {
            console.error('Failed to delete attachment', error);
        }

        //after deletion, close delete modal
        await setShowDeleteAttachmentModal(false);

        //re-render ViewAttachments modal
        await promptViewAttachments()

        //show ViewAttachments modal
        setShowViewAttachmentsModal(true);

    };

    // Handle Email //
    const handleEmailMeetingNote = async () => {
        try {
            await MeetingService.EmailMeetingNotes(meetingID);
            alert('Email successfully sent');
            setShowEmailModal(false); // Close the modal after sending
        } catch (error) {
            console.error("Failed to email meeting notes:", error);
            alert("Failed to send email. Please try again.");
        }
    };

// Return //
    return (
        <>
            <div className="meeting-notes-container">
                <h1>Meeting Notes</h1>
                <div className="topic-date-time-groups-container">
                    <div className="topic-description-container">
                        <div className="topic-header-description">
                            <h2 className="topic">Topics</h2>
                            <p className="topic-description">{topicsText}</p>
                        </div>
                    </div>
                    <div className="date-time-groups-container">
                        <div className="date-time-groups-headers">
                            <h3 className="date-time-header">Date & Time</h3>
                            <h3 className="groups-header">Groups</h3>
                        </div>
                        <div className="date-time-groups-field-values">
                            <p className="date-time">{dateTime}</p>
                            <p className="groups-needed">{groupsText}</p>
                        </div>
                    </div>
                </div>
                <div className="attachments-container">
                    <div className="attachments-header-container">
                        <h2 className="attachments-header">Attachments</h2>
                    </div>
                    <div className="add-view-attachments-links">
                        {isSalesOrAdmin ? (
                            <button className="add-attachment-link" onClick={promptAddAttachment}>Add Attachment</button>
                        ): ""}
                        <button className="view-attachment-link" onClick={promptViewAttachments}>View Attachments</button>
                    </div>
                </div>
                <div className="meeting-notes-main-container">
                    <div className="agenda-container-wrapper">
                        <form method="post" onSubmit={handleSubmitAgenda} className="agenda-container">
                            <div className="agenda-header-edit-delete-container">
                                <h2 className="agenda-header">Agenda</h2>
                                    {isSalesOrAdmin ? (
                                        <div className="agenda-edit-delete-buttons-container">
                                                <button type="button" className="agenda-delete-button" onClick={promptDeleteAgenda}>X</button>
                                        </div>
                                    ) : ""}
                            </div>
                            <label className={`agenda-content-container ${isEditingAgenda ? 'editing' : ''}`}>
                                <textarea
                                    name="agendaContent"
                                    className={isEditingAgenda ? 'textarea-agenda-editing' : 'textarea-agenda'}
                                    value={updatingAgendaText !== undefined ? updatingAgendaText : agendaText}
                                    onChange={(e) => setUpdatingAgendaText(e.target.value)}
                                    rows="5"
                                ></textarea>
                            </label>
                            <div className="agenda-save-cancel-buttons-container">
                                <button type="submit" className={`${isEditingAgenda ? 'saveCancelButton' : 'agenda-save-button-hidden'}`}>{agendaSaveButtonText}</button>
                                <button type="button" onClick={handleCancelAgenda} className={`${isEditingAgenda ? 'saveCancelButton' : 'agenda-cancel-button-hidden'}`}>Cancel</button>
                            </div>
                        </form>
                    </div>
                    <div className="action-items-container-wrapper">
                        <form method="post" onSubmit={handleSubmitActionItems} className="action-items-container">
                            <div className="action-items-header-edit-delete-container">
                                <h2 className="action-items-header">Action Items</h2>
                                {isSalesOrAdmin ? (
                                    <div className="action-items-edit-delete-buttons-container">
                                        <button type="button" className="action-items-delete-button" onClick={promptDeleteActionItems}>X</button>
                                    </div>
                                ): ""}
                            </div>
                            <label className={`action-items-content-container ${isEditingActionItems ? 'editing' : ''}`}>
                                <textarea
                                    name="actionItemsContent"
                                    className={isEditingActionItems ? 'textarea-action-items-editing' : 'textarea-action-items'}
                                    value={updatingActionItemsText !== undefined ? updatingActionItemsText : actionItemsText}
                                    onChange={(e) => setUpdatingActionItemsText(e.target.value)}
                                    rows="5"
                                ></textarea>
                            </label>
                            <div className="action-items-save-cancel-buttons-container">
                                <button type="submit" className={`${isEditingActionItems ? 'saveCancelButton' : 'action-items-save-button-hidden'}`}>{actionItemsSaveButtonText}</button>
                                <button type="button" onClick={handleCancelActionItems} className={`${isEditingActionItems ? 'saveCancelButton' : 'action-items-cancel-button-hidden'}`}>Cancel</button>
                            </div>
                        </form>
                    </div>
                    <div className="discussion-notes-container-wrapper">
                        <form method="post" onSubmit={handleSubmitDiscussionNotes} className="discussion-notes-container">
                            <div className="discussion-notes-header-edit-delete-container">
                                <h2 className="discussion-notes-header">Discussion Notes</h2>
                                {isSalesOrAdmin ? (
                                    <div className="discussion-notes-edit-delete-buttons-container">
                                        <button type="button" aria-label="Delete" className="discussion-notes-delete-button" onClick={promptDeleteDiscussionNotes}>X</button>
                                    </div>
                                ): ""}
                            </div>
                            <label className={`discussion-notes-content-container ${isEditingDiscussionNotes ? 'editing' : ''}`}>
                                <textarea
                                    name="discussionNotesContent"
                                    className={isEditingDiscussionNotes ? 'textarea-discussion-notes-editing' : 'textarea-discussion-notes'}
                                    value={updatingDiscussionNotesText !== undefined ? updatingDiscussionNotesText : discussionNotesText}
                                    onChange={(e) => setUpdatingDiscussionNotesText(e.target.value)}
                                    rows="5"
                                ></textarea>
                            </label>
                            <div className="discussion-notes-save-cancel-buttons-container">
                                <button type="submit" className={`${isEditingDiscussionNotes ? 'saveCancelButton' : 'discussion-notes-save-button-hidden'}`}>{discussionNotesSaveButtonText}</button>
                                <button type="button" onClick={handleCancelDiscussionNotes} className={`${isEditingDiscussionNotes ? 'saveCancelButton' : 'discussion-notes-cancel-button-hidden'}`}>Cancel</button>
                            </div>
                        </form>
                    </div>
                    <div className="decisions-made-container-wrapper">
                        <form method="post" onSubmit={handleSubmitDecisionsMade} className="decisions-made-container">
                            <div className="decisions-made-header-edit-delete-container">
                                <h2 className="decisions-made-header">Decisions Made</h2>
                                {isSalesOrAdmin ? (
                                    <div className="decisions-made-edit-delete-buttons-container">
                                        <button type="button" className="decisions-made-delete-button" onClick={promptDeleteDecisionsMade}>X</button>
                                    </div>
                                ): ""}
                            </div>
                            <label className={`decisions-made-content-container ${isEditingDecisionsMade ? 'editing' : ''}`}>
                                <textarea
                                    name="decisionsMadeContent"
                                    className={isEditingDecisionsMade ? 'textarea-decisions-made-editing' : 'textarea-decisions-made'}
                                    value={updatingDecisionsMadeText !== undefined ? updatingDecisionsMadeText : decisionsMadeText}
                                    onChange={(e) => setUpdatingDecisionsMadeText(e.target.value)}
                                    rows="5"
                                ></textarea>
                            </label>
                            <div className="decisions-made-save-cancel-buttons-container">
                                <button type="submit" className={`${isEditingDecisionsMade ? 'saveCancelButton' : 'decisions-made-save-button-hidden'}`}>{decisionsMadeSaveButtonText}</button>
                                <button type="button" onClick={handleCancelDecisionsMade} className={`${isEditingDecisionsMade ? 'saveCancelButton' : 'decisions-made-cancel-button-hidden'}`}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {isSalesOrAdmin ? (
                <div className="send-email-button-container">
                    <button type="button" className="send-email-button" onClick={() => setShowEmailModal(true)}>Send Email</button>
                </div>
            ) : ""}
            {showEmailModal && <MeetingNotesEmailModal showEmailModal={showEmailModal} closeModal={() => setShowEmailModal(false)} handleEmailMeetingNote={handleEmailMeetingNote} />}
            {showAddAttachmentModal && <AddAttachmentModal showModal={showAddAttachmentModal} closeModal={() => setShowAddAttachmentModal(false)} handleAddAttachment={handleAddAttachment} />}
            {showViewAttachmentsModal && <ViewAttachmentModal showModal={showViewAttachmentsModal} closeModal={() => setShowViewAttachmentsModal(false)} subtaskOrMeetingName={meetingNote.topics} modalType={'meeting'} attachments={attachments} handleDownloadAttachment={handleDownloadAttachment} promptDeleteAttachment={promptDeleteAttachment} />}
            {showDeleteAttachmentModal && <DeleteAttachmentModal showModal={showDeleteAttachmentModal} closeModal={() => setShowDeleteAttachmentModal(false)} handleDeleteAttachment={handleDeleteAttachment} attachmentToDelete={attachmentToDelete} modalType={'meeting'} />}
        </>
    );
};

export default MeetingNotes;