import { API_URL } from "../config";
import { LoginService } from "./LoginService";
import { handleExpiredToken, handleResponse, handleError } from "../helpers/ServicesHelper";

const GroupService = {

    getGroups: () => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Groups`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    getGroupsByCuId: (cuId) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Groups/${cuId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },


    getGroupsWithCompletionByCuId: (cuId) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Groups/getGroupsWithCompletion/${cuId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    getOverallCompletionByCuId: (cuId) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Groups/getOverallCompletion/${cuId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },


    createGroup: async (groupData) => {
        try {
            const userToken = LoginService.getCookie('token');
            const response = await fetch(`${API_URL}/Groups`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify(groupData),
            });
            const checkedResponse = await handleExpiredToken(response)
            return await handleResponse(checkedResponse);
        } catch (error) {
            handleError(error);
        }
    },

    updateGroup: (id, groupData) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Groups/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(groupData),
        }).then(handleExpiredToken).catch(handleError);
    },

    deleteGroup: (id) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Groups/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).catch(handleError);
    },



    getCritItems: (CUID) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Groups/getCritItems/${CUID}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },






};

export default GroupService;