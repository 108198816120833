import { NavLink } from "react-router-dom";
import { DatetimeHelper } from "../../helpers/DatetimeHelper";
import MeetingService from "../../services/MeetingService";
import { useEffect, useState } from "react";
import '../../styles/UpcomingMeeting.css';

// Critical Tasks Component
const UpcomingMeeting = ({ cuId }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [upcomingMeeting, setUpcomingMeeting] = useState({})

    useEffect(() => {
        fetchUpcomingMeeting()
    }, [cuId])

    const fetchUpcomingMeeting = async () => {
        try {
            //instead of making sure future meetings are after NOW, give an hour buffer
            const anHourAgo = new Date(3600000 * ((Date.now() / 3600000) - 1));
            const allMeetings = await MeetingService.getMeetingsByCUID(cuId)

            allMeetings.forEach(meeting => {
                meeting.date = DatetimeHelper.toLocalISOString(DatetimeHelper.convertToLocal(meeting.date))
            });

            const onlyFutureMeetings = allMeetings.filter(meeting => new Date(meeting.date) > anHourAgo)
            const sortedFutureMeetings = onlyFutureMeetings.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            const nextUpcomingMeeting = sortedFutureMeetings[0]
            nextUpcomingMeeting.groups = await fetchMeetingGroupsByMeetingID(nextUpcomingMeeting.id)
            setUpcomingMeeting(nextUpcomingMeeting)

        } catch (error) {
            console.error("Failed to fetch next upcoming meeting", error)
        } finally {
            setIsLoading(false)
        }
    };

    const fetchMeetingGroupsByMeetingID = async (meetingID) => {
        try {
            const groupsArray = await MeetingService.getGroupsByMeetingID(meetingID)
            return groupsArray.map(group => group.name).join(", ")
        } catch (error) {
            console.error('Error fetching meeting groups:', error)
            return ''
        }
    };

    const formatDateTime = (dateString) => {
        return new Date(dateString).toLocaleString(undefined, {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',})
    }


    return isLoading ?
        <div>Loading...</div>
        : upcomingMeeting ?
<>
        <h2 className="upcoming-meeting-header">Next Upcoming Meeting</h2>
            <div className="upcoming-meeting-container">

                <div className="upcoming-meeting-info">
                    <p className="upcoming-meeting-text larger">{formatDateTime(upcomingMeeting.date)}</p>
                    <p className="upcoming-meeting-text">Groups Needed: {upcomingMeeting.groups}</p>
                    <p className="upcoming-meeting-text">Topics: {upcomingMeeting.topics}</p>
                </div>

                <div className="meeting-schedule-button-container">
                    <NavLink to="/meetings">
                        <button className='meeting-schedule-button'>Meeting Schedule</button>
                    </NavLink>
                </div>

            </div>
            </>

            : <p>No Future Meetings Scheduled</p>

};

export default UpcomingMeeting;