import { useUserContext } from '../../context/UserContext';
import '../../styles/Modal.css';

export const ViewAttachmentModal = ({ showModal, closeModal, subtaskOrMeetingName, handleDownloadAttachment, promptDeleteAttachment, attachments, modalType }) => {

    const { isSalesOrAdmin } = useUserContext();

    if (!showModal) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3 style={{ marginBottom: 0 }}>{subtaskOrMeetingName}</h3>
                <h4 style={{ marginTop: 0, marginBottom: "1rem" }}>Attachments</h4>
                {/* <div style={{marginTop: 0, marginBottom: "1rem"}}>(click to download)</div> */}

                {attachments.length > 0 ? attachments.map(attachment => (
                    <div key={`attachment--${attachment.id}`} className='attachment-container'>
                        <div 
                            id={`attachment--${attachment.id}`}
                            className="attachment-link"
                            onClick={(event) => {
                                event.preventDefault()
                                handleDownloadAttachment(attachment.key, attachment.name)
                            }}>
                            {attachment.name}
                        </div>

                        {(isSalesOrAdmin) ?
                            <button className="delete-attachment-button" onClick={() => promptDeleteAttachment(attachment)}>X</button>
                            : <></>
                        }
                    </div>

                )) : <div style={{ marginBottom: "1rem" }}>This {modalType} has no attachments.</div>}

                <div className="modal-footer" style={{ justifyContent: "center" }}>
                    <button type="button" className="btn-modal" onClick={closeModal}>Close</button>
                </div>

            </div>
        </>
    );
};