import React from 'react';
import '../../styles/Modal.css';

export const MeetingNotesEmailModal = ({ showEmailModal, closeModal, handleEmailMeetingNote }) => {

    if (!showEmailModal) return null;

    return (

        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Send Email</h3>
                <p>Please confirm that you would like to send all meeting participants an email of the meeting details?</p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={closeModal}>Cancel</button>
                    <button className="btn-modal" onClick={handleEmailMeetingNote}>Confirm</button>
                </div>
            </div>
        </>

    );
};
