import React, { useState, createContext, useContext, useCallback } from 'react';
import TaskService from '../services/TaskService';
import SubtaskService from '../services/SubtaskService';

const TaskContext = createContext();

export const useTask = () => useContext(TaskContext);

export const TaskProvider = ({ children }) => {
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [selectedTaskData, setSelectedTaskData] = useState(null);
    const [taskName, setTaskName] = useState();
    const [taskDescription, setTaskDescription] = useState();

    const deleteTaskAndSubtasks = async (taskId) => {
        setSelectedTaskId(taskId);
        try {
            // Delete all subtasks for the task
            const subtasks = await SubtaskService.getSubtasksByTaskId(selectedTaskData.id);
            for (const subtask of subtasks) {
                await SubtaskService.deleteSubtask(subtask.id);
            }

            // Then delete the task
            await TaskService.deleteTask(selectedTaskData.id);
            setSelectedTaskId(null); // Clear selected task
            setSelectedTaskData(null); // Clear selected task data
        } catch (error) {
            console.error('Error deleting task and subtasks:', error);
        }
    };

    const setTaskAndName = (taskData) => {
        setSelectedTaskData(taskData);
        setTaskName(taskData ? taskData.name : null);
        setTaskDescription(taskData ? taskData.description : null);
    };

    return (
        <TaskContext.Provider value={{ selectedTaskId, setSelectedTaskId, selectedTaskData, setSelectedTaskData, setTaskAndName, taskName, taskDescription, deleteTaskAndSubtasks }}>
            {children}
        </TaskContext.Provider>
    );
};

export default TaskContext;
