import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import "../../styles/MeetingsSchedule.css";
import MeetingService from "../../services/MeetingService";
import AddMeetingModal from "./AddMeetingModal";
import EditMeetingModal from "./EditMeetingModal";
import DeleteMeetingModal from "./DeleteMeetingModal";
import { useCu } from "../../context/CuContext";
import { useUserContext } from '../../context/UserContext';
import { DatetimeHelper } from '../../helpers/DatetimeHelper';
import { AddAttachmentModal } from '../common/AddAttachmentModal';
import { AttachmentService } from '../../services/AttachmentService';
import { ViewAttachmentModal } from '../common/ViewAttachmentsModal';
import { DeleteAttachmentModal } from '../common/DeleteAttachmentModal';

const MeetingsSchedule = () => {
    const { selectedCuId, checkSessionStorageCuId } = useCu();
    const { isSalesOrAdmin } = useUserContext();

    const [meetings, setMeetings] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedMeetingID, setSelectedMeetingId] = useState();

    const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false);
    const [addAttachmentMeetingID, setAddAttachmentMeetingID] = useState();

    const [showViewAttachmentsModal, setShowViewAttachmentsModal] = useState(false);
    const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] = useState(false);

    const [attachments, setAttachments] = useState([]);
    const [viewAttachmentsMeeting, setViewAttachmentsMeeting] = useState();
    const [attachmentToDelete, setAttachmentToDelete] = useState();

    useEffect(() => {
        if (selectedCuId) {
            fetchMeetingsByCUID(selectedCuId);
            window.sessionStorage.setItem("selectedCuId", selectedCuId);
        }
        if (!selectedCuId) {
            checkSessionStorageCuId();
        }
    }, [selectedCuId]);

    const fetchMeetingsByCUID = async (CUID) => {
        try {
            const response = await MeetingService.getMeetingsByCUID(CUID);
            const meetingsWithGroups = await Promise.all(response.map(async (meeting) => {
                const meetingGroups = await fetchMeetingGroupsByMeetingID(meeting.id);
                meeting.date = DatetimeHelper.toLocalISOString(DatetimeHelper.convertToLocal(meeting.date))
                return { ...meeting, groups: meetingGroups };
            }));
            setMeetings(meetingsWithGroups);
        } catch (error) {
            console.error('Error fetching meetings:', error);
        }
    };

    const fetchMeetingGroupsByMeetingID = async (meetingID) => {
        try {
            const response = await MeetingService.getGroupsByMeetingID(meetingID);
            return response.map(group => group.name).join(", ");
        } catch (error) {
            console.error('Error fetching meeting groups:', error);
            return '';
        }
    };

    const isPastDate = (dateString) => {
        const anHourAgo = new Date(3600000 * ((Date.now() / 3600000) - 1));
        const meetingDate = new Date(dateString);
        return meetingDate < anHourAgo;
    };

    const upcomingMeetings = meetings.filter(meeting => !isPastDate(meeting.date)).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const priorMeetings = meetings.filter(meeting => isPastDate(meeting.date)).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const closeDeleteModal = () => {
        setShowDeleteModal(false)
        setSelectedMeetingId();
        fetchMeetingsByCUID(selectedCuId);
    }

    const closeEditModal = () => {
        setShowEditModal(false)
        setSelectedMeetingId();
        fetchMeetingsByCUID(selectedCuId);
    }

    const handleAddMeeting = async (meetingData, meetingGroups) => {
        try {
            const response = await MeetingService.createMeeting({ ...meetingData, cuId: selectedCuId });
            for (const group of meetingGroups) {
                const newMeetingGroup = {
                    meetingId: response.id,
                    groupId: group.id
                };
                await MeetingService.createMeetingGroup(newMeetingGroup);
            }
            fetchMeetingsByCUID(selectedCuId);
        } catch (error) {
            console.error('Creating meeting failed', error);
        }
        setShowAddModal(false);
    };

    const handleEditMeeting = async (updatedMeetingData, updatedMeetingGroups) => {
        try {
            await MeetingService.updateMeeting(selectedMeetingID, { ...updatedMeetingData, cuId: selectedCuId });
            const formerMeetingGroups = await MeetingService.getMeetingGroupsByMeetingID(selectedMeetingID);
            for (const meetingGroup of formerMeetingGroups) {
                MeetingService.deleteMeetingGroup(meetingGroup.id);
            }
            for (const group of updatedMeetingGroups) {
                if (group.checked) {
                    const newMeetingGroup = {
                        meetingId: selectedMeetingID,
                        groupId: group.id
                    };
                    await MeetingService.createMeetingGroup(newMeetingGroup);
                }
            }
            fetchMeetingsByCUID(selectedCuId);
        } catch (error) {
            console.error('Updating meeting failed', error);
        }
        setShowEditModal(false);
    };

    const promptDelete = async (meetingID) => {
        await setSelectedMeetingId(meetingID);
        setShowDeleteModal(true);
    }

    const promptEdit = async (meetingID) => {
        await setSelectedMeetingId(meetingID);
        setShowEditModal(true);
    }

    const promptAddAttachment = (meetingID) => {
        setAddAttachmentMeetingID(meetingID);
        setShowAddAttachmentModal(true);
    };

    const promptViewAttachments = async (meeting) => {
        try {
            await setViewAttachmentsMeeting(meeting)
            const response = await AttachmentService.getAttachmentsByMeeting(meeting.id)
            await setAttachments(response);
            setShowViewAttachmentsModal(true)
        } catch (error) {
            console.error('Failed to fetch attachments', error);
        }
    };

    const promptDeleteAttachment = (attachment) => {
        setAttachmentToDelete(attachment)
        setShowViewAttachmentsModal(false);
        setShowDeleteAttachmentModal(true)
    };

    const handleAddAttachment = async (formData) => {
        try {
            await AttachmentService.uploadAttachment(formData, addAttachmentMeetingID, 0);
        } catch (error) {
            console.error('Failed to upload attachment', error);
        }
        setShowAddModal(false);
    };

    const handleDownloadAttachment = async (attachmentKey, attachmentName) => {
        try {
            await AttachmentService.downloadAttachment(attachmentKey, attachmentName);
        } catch (error) {
            console.error('Failed to download attachment', error);
        }
        setShowAddModal(false);
    };

    const handleDeleteAttachment = async (id) => {
        try {
            await AttachmentService.deleteAttachment(id);
        } catch (error) {
            console.error('Failed to delete attachment', error);
        }
        await setShowDeleteAttachmentModal(false);
        await promptViewAttachments(viewAttachmentsMeeting)
        setShowViewAttachmentsModal(true);
    };

    const closeDeleteAttachmentModal = async () => {
        await setShowDeleteAttachmentModal(false);
        setShowViewAttachmentsModal(true);
    }

    const formatDateTime = (dateString) => {
        return new Date(dateString).toLocaleString(undefined, {
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        })
    }

    return (
        <div className="upcoming-meetings-container">
            <h1>Upcoming Meetings</h1>
            <div className="upcoming-meetings-table">
                <div className="upcoming-meetings-header">
                    <div className="upcoming-meetings-date-time-header">Date & Time</div>
                    <div className="upcoming-meetings-groups-header">Groups</div>
                    <div className="upcoming-meetings-topics-header">Topic</div>
                </div>
                {upcomingMeetings.length > 0 ? (
                    upcomingMeetings.map(meeting => (
                        <div key={meeting.id} className="upcoming-meetings-columns">
                            <div className="upcoming-meeting-wrapper">
                                {isSalesOrAdmin ? (
                                    <div className="upcoming-meetings-record sales-admin-button">
                                        <NavLink className="upcoming-meetings-date-time navlink" to={`/meeting-notes/${meeting.id}`}>
                                            <div className="meeting-detail"><span className="meeting-label">Date & Time:</span> {formatDateTime(meeting.date)}</div>
                                        </NavLink>
                                        <NavLink className="upcoming-meetings-groups navlink" to={`/meeting-notes/${meeting.id}`}>
                                            <div className="meeting-detail"><span className="meeting-label">Groups:</span> {meeting.groups}</div>
                                        </NavLink>
                                        <div className="upcoming-meetings-topics meeting-detail">
                                            <NavLink className="navlink" to={`/meeting-notes/${meeting.id}`}>
                                                <span className="meeting-label">Topic:</span> {meeting.topics}
                                            </NavLink>
                                            <div className="upcoming-meetings-record-buttons">
                                                <button type="button" aria-label="Edit" className="meetings-schedule-record-buttons-delete" onClick={() => promptEdit(meeting.id)}>✏️</button>
                                                <button type="button" aria-label="Delete" className="meetings-schedule-record-buttons-delete" onClick={() => promptDelete(meeting.id)}>X</button>
                                                <button type="button" onClick={() => promptAddAttachment(meeting.id)} aria-label="Add Attachment">📎</button>
                                                <button type="button" onClick={() => promptViewAttachments(meeting)} aria-label="View Attachments">view</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="upcoming-meetings-record">
                                        <NavLink className="upcoming-meetings-date-time navlink" to={`/meeting-notes/${meeting.id}`}>
                                            <div className="meeting-detail"><span className="meeting-label">Date & Time:</span> {formatDateTime(meeting.date)}</div>
                                        </NavLink>
                                        <NavLink className="upcoming-meetings-groups navlink" to={`/meeting-notes/${meeting.id}`}>
                                            <div className="meeting-detail"><span className="meeting-label">Groups:</span> {meeting.groups}</div>
                                        </NavLink>
                                        <div className="upcoming-meetings-topics meeting-detail">
                                            <NavLink className="navlink" to={`/meeting-notes/${meeting.id}`}>
                                                <span className="meeting-label">Topic:</span> {meeting.topics}
                                            </NavLink>
                                            <div className="upcoming-meetings-record-buttons">
                                                <button type="button" onClick={() => promptAddAttachment(meeting.id)} aria-label="Add Attachment">📎</button>
                                                <button type="button" onClick={() => promptViewAttachments(meeting)} aria-label="View Attachments">view</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="upcoming-meetings-columns">
                        <div className="upcoming-meeting-wrapper">
                            <p>No meeting data available to display.</p>
                        </div>
                    </div>
                )}
            </div>

            {isSalesOrAdmin ?
                <button onClick={() => setShowAddModal(true)} className="add-meeting-button">➕ Add Meeting</button>
                : <div className="blank-space"></div>
            }

            {priorMeetings.length > 0 && (
                <div className="upcoming-meetings-container">
                    <h1>Prior Meetings</h1>
                    <div className="prior-meetings-container">
                        <div className="upcoming-meetings-header">
                            <div className="upcoming-meetings-date-time-header">Date & Time</div>
                            <div className="upcoming-meetings-groups-header">Groups</div>
                            <div className="upcoming-meetings-topics-header">Topic</div>
                        </div>
                        {priorMeetings.map(meeting => (
                            <div key={meeting.id} className="upcoming-meetings-columns">
                                <div className="upcoming-meeting-wrapper">
                                    {isSalesOrAdmin ? (
                                        <div className="upcoming-meetings-record sales-admin-button">
                                            <NavLink className="upcoming-meetings-date-time navlink" to={`/meeting-notes/${meeting.id}`}>
                                                <div className="meeting-detail"><span className="meeting-label">Date & Time:</span> {formatDateTime(meeting.date)}</div>
                                            </NavLink>
                                            <NavLink className="upcoming-meetings-groups navlink" to={`/meeting-notes/${meeting.id}`}>
                                                <div className="meeting-detail"><span className="meeting-label">Groups:</span> {meeting.groups}</div>
                                            </NavLink>
                                            <div className="upcoming-meetings-topics meeting-detail">
                                                <NavLink className="navlink" to={`/meeting-notes/${meeting.id}`}>
                                                    <span className="meeting-label">Topic:</span> {meeting.topics}
                                                </NavLink>
                                                <div className="upcoming-meetings-record-buttons">
                                                    <button type="button" aria-label="Edit" className="meetings-schedule-record-buttons-delete" onClick={() => promptEdit(meeting.id)}>✏️</button>
                                                    <button type="button" aria-label="Delete" className="meetings-schedule-record-buttons-delete" onClick={() => promptDelete(meeting.id)}>X</button>
                                                    <button type="button" onClick={() => promptAddAttachment(meeting.id)} aria-label="Add Attachment">📎</button>
                                                    <button type="button" onClick={() => promptViewAttachments(meeting)} aria-label="View Attachments">view</button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="upcoming-meetings-record">
                                            <NavLink className="upcoming-meetings-date-time navlink" to={`/meeting-notes/${meeting.id}`}>
                                                <div className="meeting-detail"><span className="meeting-label">Date & Time:</span> {formatDateTime(meeting.date)}</div>
                                            </NavLink>
                                            <NavLink className="upcoming-meetings-groups navlink" to={`/meeting-notes/${meeting.id}`}>
                                                <div className="meeting-detail"><span className="meeting-label">Groups:</span> {meeting.groups}</div>
                                            </NavLink>
                                            <div className="upcoming-meetings-topics meeting-detail">
                                                <NavLink className="navlink" to={`/meeting-notes/${meeting.id}`}>
                                                    <span className="meeting-label">Topic:</span> {meeting.topics}
                                                </NavLink>
                                                <div className="upcoming-meetings-record-buttons">
                                                    <button type="button" onClick={() => promptAddAttachment(meeting.id)} aria-label="Add Attachment">📎</button>
                                                    <button type="button" onClick={() => promptViewAttachments(meeting)} aria-label="View Attachments">view</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {showAddModal && <AddMeetingModal show={showAddModal} onClose={() => setShowAddModal(false)} handleAddMeeting={handleAddMeeting} selectedCuID={selectedCuId} />}
            {showEditModal && <EditMeetingModal show={showEditModal} onClose={closeEditModal} handleEditMeeting={handleEditMeeting} selectedCuID={selectedCuId} selectedMeetingID={selectedMeetingID} meetings={meetings} formatDateTime={formatDateTime} />}
            {showDeleteModal && <DeleteMeetingModal show={showDeleteModal} onClose={closeDeleteModal} selectedMeetingID={selectedMeetingID} />}
            {showAddAttachmentModal && <AddAttachmentModal showModal={showAddAttachmentModal} closeModal={() => setShowAddAttachmentModal(false)} handleAddAttachment={handleAddAttachment} />}
            {showViewAttachmentsModal && <ViewAttachmentModal showModal={showViewAttachmentsModal} closeModal={() => setShowViewAttachmentsModal(false)} subtaskOrMeetingName={viewAttachmentsMeeting.name} handleDownloadAttachment={handleDownloadAttachment} promptDeleteAttachment={promptDeleteAttachment} attachments={attachments} modalType={'meeting'} />}
            {showDeleteAttachmentModal && <DeleteAttachmentModal show={showDeleteAttachmentModal} closeModal={closeDeleteAttachmentModal} handleDeleteAttachment={handleDeleteAttachment} attachmentToDelete={attachmentToDelete} />}
            <div className="blank-space">
            </div>
        </div>
    );
};

export default MeetingsSchedule;
