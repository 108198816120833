import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGroup } from '../../context/GroupContext';
import { useTask } from '../../context/TaskContext';
import GroupService from '../../services/GroupService';
import '../../styles/CriticalTasks.css';
import { useCu } from '../../context/CuContext';
import CreditUnionService from '../../services/CreditUnionService';
import { CritItemEmailModal } from './CritItemEmailModal';
import { useUserContext } from '../../context/UserContext';

// Dropdown Component
const Dropdown = ({ title, tasks, onToggle, isOpen, children }) => {

    const dropdownClass = isOpen ? "dropdown active" : "dropdown";
    const dropdownGroupClassName = isOpen ? "underline" : "";

    return (
        (!tasks ? (
            <div className={dropdownClass}>
                <div className="dropdown-toggle" onClick={onToggle}>
                    <button type="button" className="critical-tasks-button">{isOpen ? <span className="material-symbols-outlined">
                        expand_more
                    </span> : <span className="material-symbols-outlined">
                        chevron_right
                    </span>}</button>
                    <p className={dropdownGroupClassName}>{title}</p>
                </div>
                {isOpen && <div className="dropdown-content">{children}</div>}
            </div >
        ) : (
            <div className="tasks-dropdown">
                <div className="tasks-dropdown-toggle" onClick={onToggle}>
                    <button type="button" className="tasks-button">{isOpen ? <span className="material-symbols-outlined">
                        expand_more
                    </span> : <span className="material-symbols-outlined">
                        chevron_right
                    </span>}</button>
                    <p>{title}</p>
                </div>
                {isOpen && <div className="tasks-dropdown-content">{children}</div>}
            </div >
        )
        )
    );
};

// Critical Tasks Component
const CriticalTasks = ({ cuId }) => {

    const { isSalesOrAdmin } = useUserContext();
    const { setGroupName } = useGroup();
    const { setSelectedTaskData } = useTask();
    const location = useLocation();
    const noTasksPendingMessage = "No tasks pending";
    const [isLoading, setIsLoading] = useState(true);

    const [openedGroupIds, setOpenedGroupIds] = useState(new Set());
    const [openedTaskIds, setOpenedTaskIds] = useState(new Set());
    const [selectedTaskId, setSelectedTaskId] = useState(null);

    const [groupsWithCritItems, setGroupsWithCritItems] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);


    const { selectedCuId } = useCu();

    // useEffects
    useEffect(() => {
        fetchInitialData();
    }, [cuId]);

    useEffect(() => {
        setSelectedTaskId(null);
    }, [location]);

    // Fetches
    const fetchInitialData = async () => {
        try {
            const fetchedGroups = await GroupService.getCritItems(selectedCuId);
            await setGroupsWithCritItems(fetchedGroups)
        } catch (error) {
            console.error("Failed to fetch groups or tasks:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Utility
    const toggleTasks = (groupId) => {
        setOpenedGroupIds(prev => {
            const newOpened = new Set(prev);
            if (newOpened.has(groupId)) {
                newOpened.delete(groupId);
            } else {
                newOpened.add(groupId);
            }
            return newOpened;
        });
    };

    const toggleSubtasks = (taskId) => {
        setOpenedTaskIds(prev => {
            const newOpened = new Set(prev);
            if (newOpened.has(taskId)) {
                newOpened.delete(taskId);
            } else {
                newOpened.add(taskId);
            }
            return newOpened;
        });
    };

    // Handlers //
    const navLinkClickHandler = (group, task) => {
        setGroupName(group.name);
        setSelectedTaskData(task);
        setSelectedTaskId(task.id); // Set the selected task ID

        localStorage.setItem('selectedGroup', JSON.stringify(group));
        localStorage.setItem('selectedTask', JSON.stringify(task));
    };


    const handleEmailCritItems = () => {
        try {
            CreditUnionService.emailCritItems(selectedCuId, groupsWithCritItems)
            alert('Email successfully sent')
        }
        catch (error) {
            alert("Failed to email crit items to users", error);
        }
        setShowEmailModal(false);
    }


    // Return //
    return (
        isLoading ? (
            <div>Loading...</div>
        ) : groupsWithCritItems.length > 0 ? (

            <>
                <div className="critical-tasks-container">
                    <div className="critical-tasks-list">
                        <h2>Critical Items Pending</h2>
                        <div className="critical-groups">
                            {groupsWithCritItems.map(group => (
                                <Dropdown
                                    key={`critgroup--${group.id}`}
                                    title={group.name}
                                    groups={groupsWithCritItems}
                                    onToggle={() => toggleTasks(group.id)}
                                    isOpen={openedGroupIds.has(group.id)}
                                >
                                    <div className="critical-tasks">
                                        {group.tasks.map(task => (
                                            <Dropdown
                                                key={`crittask--${task.id}`}
                                                title={task.name}
                                                tasks={group.tasks}
                                                onToggle={() => toggleSubtasks(task.id)}
                                                isOpen={openedTaskIds.has(task.id)}
                                                className={selectedTaskId === task.id ? "active-task" : ""}
                                            >
                                                <div className="critical-subtasks">
                                                    {task.subtasks.map(subtask => (
                                                        <NavLink
                                                            key={`critsubtask--${subtask.id}`}
                                                            to={`/tasks/${group.id}/${task.id}/${subtask.id}`}
                                                            onClick={() => navLinkClickHandler(group, task)}
                                                        >
                                                            <div className="critical-subtasks-items">
                                                                <div className="critical-subtask-name">
                                                                    {subtask.name}
                                                                </div>
                                                                <div className="critical-subtask-date">
                                                                    <div className="due-date">Due Date:</div>
                                                                    <div className="date-div">{new Date(subtask.dueDate).toLocaleDateString()}</div>
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    ))}
                                                </div>
                                            </Dropdown>
                                        ))}
                                    </div>
                                </Dropdown>
                            ))}
                        </div>
                    </div>


                    {/* display send email button if user is sales or admin */}
                    {isSalesOrAdmin &&
                        <div className='email-button-container'>
                            <button className='email-button' onClick={() => setShowEmailModal(true)}>SEND EMAIL</button>
                        </div>
                    }

                </div>

                {showEmailModal && <CritItemEmailModal showEmailModal={showEmailModal} closeModal={() => setShowEmailModal(false)} handleEmailCritItems={handleEmailCritItems} />}

            </>
        ) : (
            <p>{noTasksPendingMessage}</p>
        )


    );
};

export default CriticalTasks;
