//import styles from Manage Accts, in addition to CreditUnions.css
import '../../styles/ManageAccts.css';
import '../../styles/CreditUnions.css';

import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import CreditUnionService from '../../services/CreditUnionService';
import { EditNameModal } from './EditNameModal';
import { EditLogoModal } from './EditLogoModal';
import { DisableModal } from './DisableModal';
import { AddCUModal } from './AddCUModal';
import GroupService from '../../services/GroupService';
import TaskService from '../../services/TaskService';
import SubtaskService from '../../services/SubtaskService';
import { useCu } from '../../context/CuContext';
import { useUserContext } from '../../context/UserContext';
import { AttachmentService } from '../../services/AttachmentService';

export const CreditUnions = () => {

    const [isLoading, setIsLoading] = useState(true);
    const { allCreditUnions, setAllCreditUnions } = useCu();
    const [CUToEdit, setCUToEdit] = useState();
    const [CUToDisable, setCUToDisable] = useState();
    const [newLogo, setNewLogo] = useState([]);
    const { isAdmin } = useUserContext();

    const [showEditNameModal, setShowEditNameModal] = useState(false);
    const [showEditLogoModal, setShowEditLogoModal] = useState(false);
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        fetchCreditUnions();
    }, [])

    const fetchCreditUnions = async () => {
        await setIsLoading(true);
        try {
            const listOfCreditUnions = await CreditUnionService.getAllCreditUnions();

            // Add logoURL to each creditUnion, with new presigned URL fetched from S3
            const updatedList = [];
            for (const cu of listOfCreditUnions) {
                cu.logoURL = await CreditUnionService.getCULogo(cu.brandingLogo);
                updatedList.push(cu);
            }

            setAllCreditUnions(updatedList)

        } catch (error) {
            console.error('Fetching Credit Unions failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditNamePrompt = async (event) => {
        event.preventDefault();
        const [, CUID] = event.target.id.split("--")
        const parsedCUID = parseInt(CUID)
        const foundCU = await CreditUnionService.getCreditUnionById(parsedCUID)
        await setCUToEdit(foundCU)
        await setShowEditNameModal(true)
    }

    const handleEditLogoPrompt = async (event) => {
        event.preventDefault();
        const [, CUID] = event.target.id.split("--")
        const parsedCUID = parseInt(CUID)
        const foundCU = await CreditUnionService.getCreditUnionById(parsedCUID)
        await setCUToEdit(foundCU)
        await setShowEditLogoModal(true)
    }

    const handleDisablePrompt = async (event) => {
        event.preventDefault();
        const [, CUID] = event.target.id.split("--")
        const parsedCUID = parseInt(CUID)
        const foundCU = await CreditUnionService.getCreditUnionById(parsedCUID)
        await setCUToDisable(foundCU)
        await setShowDisableModal(true)
    }

    const handleEditName = async (editedCUdata) => {
        try {
            await CreditUnionService.updateCreditUnion(editedCUdata.id, { ...editedCUdata });
            fetchCreditUnions();
        } catch (error) {
            console.error('Failed to edit credit union', error);
        }
        setShowEditNameModal(false);
    };

    const handleEditLogo = async () => {
        try {
            //create form data from newLogo
            const newLogoFormData = await new FormData()
            newLogoFormData.append('file', newLogo)

            await CreditUnionService.updateCULogo(CUToEdit.id, newLogoFormData);
            fetchCreditUnions();

        } catch (error) {
            console.error('Failed to edit credit union', error);
        }
        setShowEditNameModal(false);
    };

    const handleDisableSubmit = async () => {
        try {
            //update CU.disabled to true
            const updatedCU = await {
                id: CUToDisable.id,
                name: CUToDisable.name,
                brandingLogo: CUToDisable.brandingLogo,
                disabled: true
            }

            await CreditUnionService.updateCreditUnion(updatedCU.id, { ...updatedCU });
            fetchCreditUnions();

        } catch (error) {
            console.error('Failed to disable credit union', error);
        }
        setShowDisableModal(false);
    };

    const handleAddCU = async (newCUname, newCUlogo, groupsTemplate) => {
        try {
            await setIsLoading(true);

            // Create form data from newCUlogo
            const newLogoFormData = new FormData();
            newLogoFormData.append('file', newCUlogo);

            // Create Credit Union
            const CUResponse = await CreditUnionService.createCreditUnion(newCUname, newLogoFormData);

            // Process groupsTemplate if it's not null
            if (groupsTemplate) {
                for (const group of groupsTemplate) {
                    const newGroup = {
                        name: group.name,
                        cuId: CUResponse.id
                    };

                    // Create Group
                    const groupResponse = await GroupService.createGroup(newGroup);

                    for (const task of group.tasks) {
                        const newTask = {
                            name: task.name,
                            description: task.description,
                            groupId: groupResponse.id
                        };

                        // Create New Task
                        const taskResponse = await TaskService.createTask(newTask);

                        for (const subtask of task.subtasks) {

                            //need to make new subtask object, excluding attachments array
                            const newSubtask = {
                                name: subtask.name,
                                description: subtask.description,
                                taskId: taskResponse.id,
                                dueDate: subtask.dueDate,
                                isComplete: false
                            };

                            // Create Subtask
                            const subtaskResponse = await SubtaskService.createSubtask(newSubtask);

                            // if the subtasks has attachments, 
                            if (subtask.attachments) {
                                for (const attachment of subtask.attachments) {
                                    attachment.subtaskId = subtaskResponse.id;
                                    
                                    // Link Existing attachment to new subtask ID
                                    await AttachmentService.linkExistingAttachment(attachment);
                                    console.log(`Linked Attachment ${attachment.key}`);
                                }
                            }


                        }
                    }
                }
            }

            // Fetch updated Credit Unions
            fetchCreditUnions();

        } catch (error) {
            console.error('Creating new CU failed', error);
        }
        setShowAddModal(false);
    };

    return (

        <>
            <h1>Credit Unions</h1>
            <div className="manageacct-buttons">
                <NavLink to='/manage-accounts'>
                    <button className='manageacct-button'>Back to Users</button>
                </NavLink>

                {/* Only display VIEW DISABLED CUs and ADD CU buttons if user is TTadmin */}
                {isAdmin && <>
                    <button className='manageacct-button' onClick={() => setShowAddModal(true)}>Add Credit Union</button>
                    <NavLink to='/credit-unions/disabled'>
                        <button className='manageacct-button'>View Disabled Credit Unions</button>
                    </NavLink>
                </>
                }

            </div>

            <div className="cu-section">

                {isLoading ? (
                    <div>Loading...</div>
                ) : allCreditUnions.length > 0 ?

                    <table className="cu-table">
                        <thead>
                            <tr>
                                <th className='tableHeader--1'>Name</th>
                                <th className='tableHeader--2'>Logo</th>
                                <th className='tableHeader--7'>Manage</th>
                            </tr>
                        </thead>

                        <tbody>

                            {allCreditUnions.map((cu) => {

                                return (
                                    <tr key={`cu--${cu.id}`}>
                                        <td>{cu.name}</td>
                                        <td><img style={{ height: '5rem', width: 'auto' }} src={cu.logoURL} /></td>

                                        <td>
                                            <div className='cu-buttons-container'>
                                                <button className='cu-button blue' id={`editCUName--${cu.id}`} tag={Link} onClick={(evt) => handleEditNamePrompt(evt)}>Edit Name</button>
                                                <button className='cu-button blue' id={`editCULogo--${cu.id}`} tag={Link} onClick={(evt) => handleEditLogoPrompt(evt)}>Edit Logo</button>

                                                {/* Only display DISABLE button if user is TTadmin */}
                                                {isAdmin && <button className='cu-button red' id={`disableCU--${cu.id}`} tag={Link} onClick={(evt) => handleDisablePrompt(evt)}>Disable</button>}

                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                    : <><h1>Add a Credit Union to view table</h1></>
                }


                {showEditNameModal && <EditNameModal showEditModal={showEditNameModal} closeModal={() => setShowEditNameModal(false)} handleEditName={handleEditName} CUToEdit={CUToEdit} setCUToEdit={setCUToEdit} />}
                {showEditLogoModal && <EditLogoModal showEditModal={showEditLogoModal} closeModal={() => setShowEditLogoModal(false)} handleEditLogo={handleEditLogo} newLogo={newLogo} setNewLogo={setNewLogo} />}
                {showDisableModal && <DisableModal showDisableModal={showDisableModal} closeModal={() => setShowDisableModal(false)} handleDisableSubmit={handleDisableSubmit} />}
                {showAddModal && <AddCUModal showAddModal={showAddModal} closeModal={() => setShowAddModal(false)} handleAddCU={handleAddCU} />}
            </div>
        </>
    )
}