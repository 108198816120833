import React from 'react';
import '../../styles/Modal.css';
import '../../styles/ManageAccts.css';

export const RolesModal = ({ showRolesModal, closeModal }) => {

    if (!showRolesModal) return null;

    return (

        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content role-modal-container">
                <h3>Role Definitions</h3>
                <table className="roles-table">
                    <thead>
                        <tr>
                            <th className='tableHeader--1'>Role</th>
                            <th className='tableHeader--2 role-description-column'>Description</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr key={`role-def--1`}>
                            <td>TTadmin</td>
                            <td>Has read/write access to all Credit Unions <br/> 
                                Can edit all users and Credit Unions. <br/> 
                                Can create/disable Credit Unions.</td>
                        </tr>
                        <tr key={`role-def--2`}>
                            <td>TTsales</td>
                            <td>Has read/write access to assigned Credit Unions <br/> 
                                Can edit CUusers and Credit Unions.</td>
                        </tr>
                        <tr key={`role-def--3`}>
                            <td>CUuser</td>
                            <td>Has view only access to a single assigned Credit Union <br/> 
                                Can download/upload attachments.
                            </td>
                        </tr>
                    </tbody>
                </table>


                <div className="modal-footer" style={{justifyContent: 'center'}}>
                    <button type="button" className="btn-modal" onClick={closeModal}>Close</button>
                </div>


            </div>
        </div>

    );
};