import React, { useEffect, useState } from 'react';
import '../../styles/Modal.css';
import { getAllRoles } from '../../services/RoleService';
import { useUserContext } from '../../context/UserContext';

export const EditUserModal = ({ showEditModal, closeModal, handleEditUser, userToEdit, setUserToEdit }) => {

    const { isAdmin } = useUserContext();
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        getAllRoles().then((roles) => setRoleList(roles));
    }, [])



    const handleSubmit = (event) => {
        event.preventDefault();
        handleEditUser(userToEdit)
        closeModal();
    };

    if (!showEditModal) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Edit User</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">

                        <label htmlFor='firstName-input'>First Name</label>
                        <input
                            id='firstName-input'
                            type="text"
                            value={userToEdit.firstName}
                            onChange={
                                (event) => {
                                    const copy = { ...userToEdit }
                                    copy.firstName = event.target.value
                                    setUserToEdit(copy)
                                }
                            }
                            placeholder="First Name"
                            required
                            className="modal-input"
                        />

                        <label htmlFor='lastName-input'>Last Name</label>
                        <input
                            id='lastName-input'
                            type="text"
                            value={userToEdit.lastName}
                            onChange={
                                (event) => {
                                    const copy = { ...userToEdit }
                                    copy.lastName = event.target.value
                                    setUserToEdit(copy)
                                }
                            }
                            placeholder="Last Name"
                            required
                            className="modal-input"
                        />

                        <label htmlFor='username-input'>Username</label>
                        <input
                            id='username-input'
                            type="text"
                            value={userToEdit.username}
                            onChange={
                                (event) => {
                                    const copy = { ...userToEdit }
                                    copy.username = event.target.value
                                    setUserToEdit(copy)
                                }
                            }
                            placeholder="Username"
                            required
                            className="modal-input"
                        />

                        <label htmlFor='email-input'>Email</label>
                        <input
                            id='email-input'
                            type="text"
                            value={userToEdit.email}
                            onChange={
                                (event) => {
                                    const copy = { ...userToEdit }
                                    copy.email = event.target.value
                                    setUserToEdit(copy)
                                }
                            }
                            placeholder="Email"
                            required
                            className="modal-input"
                        />

                        {/* ONLY display role dropdown if user is TTadmin */}
                        {isAdmin ?
                            <div className='dropdown-container'>
                                <label htmlFor='role-input'>Role</label>
                                <select
                                    required
                                    id='role-input'
                                    className="modal-input dropdown"
                                    name="role"
                                    value={userToEdit.role}
                                    onChange={(event) => {
                                        const copy = { ...userToEdit }
                                        copy.role = event.target.value
                                        setUserToEdit(copy)
                                    }}
                                >
                                    {roleList.map((role) => (
                                        <option key={role.id} value={role.id}>{role.roleName}</option>
                                    ))}
                                </select>
                            </div>
                            : <></>
                        }


                            <div className='checkbox-container'>
                                <label htmlFor="receivesNotifs-checkbox">Receives Notifications</label>
                                <input
                                    id='receivesNotifs-checkbox'
                                    className="modal-input checkbox"
                                    type="checkbox"
                                    checked={userToEdit.receivesNotifs}
                                    onChange={
                                        (event) => {
                                            const copy = { ...userToEdit }
                                            copy.receivesNotifs = event.target.checked
                                            setUserToEdit(copy)
                                        }
                                    }
                                />
                            </div>



                        </div>

                        <div className="modal-footer">
                            <button type="submit" className="btn-modal">Save</button>
                            <button type="button" className="btn-modal" onClick={closeModal}>Cancel</button>
                        </div>

                </form>

            </div>
        </>
    );
};