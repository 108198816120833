import { API_URL } from "../config";
import { LoginService } from "./LoginService";
import { handleExpiredToken, handleResponse, handleError } from "../helpers/ServicesHelper";

export const AttachmentService = {

    getAttachmentsBySubtask: async (subtaskID) => {
        try {
            const userToken = await LoginService.getCookie('token')
            const response = await fetch(`${API_URL}/attachments/bySubtask/${subtaskID}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + userToken
                },
            })
            const checkedResponse = await handleExpiredToken(response)
            return handleResponse(checkedResponse)
        }
        catch (error) {
            return handleError(error)
        }
    },

    getAttachmentsByMeeting: async (meeetingID) => {
        try {
            const userToken = await LoginService.getCookie('token')
            const response = await fetch(`${API_URL}/attachments/byMeeting/${meeetingID}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + userToken
                },
            })
            const checkedResponse = await handleExpiredToken(response)
            return handleResponse(checkedResponse)
        }
        catch (error) {
            return handleError(error)
        }
    },

    uploadAttachment: async (formData, meetingID, subtaskID) => {
        try {
            const userToken = await LoginService.getCookie('token')
            const response = await fetch(`${API_URL}/attachments/?meetingID=${meetingID}&?subtaskID=${subtaskID}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + userToken
                },
                body: formData
            });
            const checkedResponse = await handleExpiredToken(response)
            return handleResponse(checkedResponse)
        }
        catch (error) {
            return handleError(error)
        }
    },


    linkExistingAttachment: async (attachment) => {
        try {
            const userToken = await LoginService.getCookie('token')
            const response = await fetch(`${API_URL}/attachments/linkExisting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify(attachment)
            });
            const checkedResponse = await handleExpiredToken(response)
            return handleResponse(checkedResponse)
        }
        catch (error) {
            return handleError(error)
        }
    },



    downloadAttachment: async (attachmentKey, attachmentName) => {
        try {
            const userToken = await LoginService.getCookie('token')
            await fetch(`${API_URL}/attachments/download/?key=${attachmentKey}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + userToken
                },
            })
                .then(handleExpiredToken)
                .then((response) => response.blob())
                .then((blob) => {

                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );

                    // Create Link element using blob URL (and rename file back to attachment.name)
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${attachmentName}`,
                    );

                    //append to body, click for user, then remove element
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
        }
        catch (error) {
            return handleError(error)
        }
    },

    deleteAttachment: async (id) => {
        try {
            const userToken = await LoginService.getCookie('token')
            const response = await fetch(`${API_URL}/attachments/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + userToken
                },
            })
            const checkedResponse = await handleExpiredToken(response)
            return handleResponse(checkedResponse)
        }
        catch (error) {
            return handleError(error)
        }
    },






}