import React, { useState, createContext, useContext } from 'react';

const SubtaskContext = createContext();

export const useSubtask = () => useContext(SubtaskContext);

export const SubtaskProvider = ({ children }) => {
    const [selectedSubtaskData, setSelectedSubtaskData] = useState();
    const [subtaskName, setSubtaskName] = useState();
    const [subtaskDescription, setSubtaskDescription] = useState();
    const [subtaskDueDate, setSubtaskDueDate] = useState();
    const [subtaskIsComplete, setSubtaskIsComplete] = useState();
    const [completedSubtasks, setCompletedSubtasks] = useState(0);

    const setSubtaskAndName = (subtaskData) => {
        setSelectedSubtaskData(subtaskData);
        setSubtaskName(subtaskData ? subtaskData.name : null);
        setSubtaskDescription(subtaskData ? subtaskData.description : null);
        setSubtaskDueDate(subtaskData ? subtaskData.dueDate : null);
        setSubtaskIsComplete(subtaskData ? subtaskData.isComplete : null);
    };

    return (
        <SubtaskContext.Provider value={{
            completedSubtasks,
            setCompletedSubtasks,
            selectedSubtaskData,
            subtaskName,
            subtaskDescription,
            subtaskDueDate,
            subtaskIsComplete,
            setSubtaskAndName,
        }}>
            {children}
        </SubtaskContext.Provider>
    );
};

export default SubtaskContext;