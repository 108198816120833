export const DatetimeHelper = {

    //convert time from military to AM/PM
    toTimeWithAMPM: (militaryTime) => {
        const timeArray = militaryTime.split(':');
        return (timeArray[0] > 12) ? (timeArray[0] - 12) + ':' + timeArray[1] + ' PM' : timeArray.join(':') + ' AM'
    },

    //convert time from military to AM/PM WITH TIMEZONE
    toTimeWithAMPMwithTZ: (militaryTime) => {
        const usersTZ = Intl.DateTimeFormat().resolvedOptions().timeZone

        const timeArray = militaryTime.split(':');
        return (timeArray[0] > 12) ? (timeArray[0] - 12) + ':' + timeArray[1] + ' PM' : timeArray.join(':') + ' AM ' + usersTZ
    },

    //convert the local ISO string received from input to UTC before it gets sent to backend
    convertToUTC: (dateString) => {
        const localDatetime = new Date(dateString)
        return new Date(localDatetime.getTime()).toISOString();
    },

    //convert time from UTC to local (returns date object)
    convertToLocal: (dateString) => {
        const UTCDatetime = new Date(dateString)
        return new Date(UTCDatetime.getTime() - UTCDatetime.getTimezoneOffset() * 60000)
    },
    

    //converts Date object to ISOString WITHOUT converting the time back to UTC
    toLocalISOString: (date) => {
        const pad = (number) => (number < 10 ? '0' + number : number);
    
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1); // Add 1 because months start at 0
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
        const milliseconds = (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5);
    
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    },

    //converts Date object to ISOString WITHOUT converting the time back to UTC
    toLocalISOString: (date) => {
        const pad = (number) => (number < 10 ? '0' + number : number);

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1); // Add 1 because months start at 0
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
        const milliseconds = (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5);

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    },

    toLocalStringDateAndTime: (dateString) => {

        //convert time from UTC to local
        const localDatetime = DatetimeHelper.convertToLocal(dateString)

        const year = localDatetime.getFullYear();
        const month = localDatetime.getMonth() + 1;
        const day = localDatetime.getDate();
        const hours = localDatetime.getHours();
        const minutes = localDatetime.getMinutes();

        // Format: MM-DD-YYYY HH:mm
        return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    },

    toLocalStringMonthAndDay: (dateString) => {

        //convert time from UTC to local
        const localDatetime = DatetimeHelper.convertToLocal(dateString)

        const month = localDatetime.getMonth() + 1;
        const day = localDatetime.getDate();

        var months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];

        var selectedMonthName = months[month];

        // Format: FullMonth DD
        return `${selectedMonthName} ${day.toString().padStart(2, '0')}`
    },

    toLocalStringTimeOnly: (dateString) => {

        //convert time from UTC to local
        const localDatetime = DatetimeHelper.convertToLocal(dateString)

        const hours = localDatetime.getHours();
        const minutes = localDatetime.getMinutes();
        const militaryTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        const formattedTime = DatetimeHelper.toTimeWithAMPM(militaryTime)

        // Format: HH:mm AM/PM
        return formattedTime;
    },



};

