import React from 'react';
import '../../styles/Modal.css';

export const DeleteAttachmentModal = ({ showModal, closeModal, handleDeleteAttachment, attachmentToDelete, modalType }) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        handleDeleteAttachment(attachmentToDelete.id)
    };

    const handleCancel = (event) => {
        event.preventDefault();
        closeModal()
    };

    if (!showModal) {
        return null;
    }

    // Determine the display text based on the modal type
    const attachmentDisplayText = modalType === 'subtask' ? attachmentToDelete.name : attachmentToDelete.topics;

    return (
        <>
            <div className="modal-overlay" onClick={handleCancel}></div>
            <div className="modal-content">
                <h3>Delete Attachment</h3>
                {/* Render the conditional display text here */}
                <p>Are you sure you want to delete {attachmentDisplayText}?</p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={handleCancel}>Cancel</button>
                    <button className="btn-modal" onClick={handleSubmit}>Delete</button>
                </div>
            </div>
        </>
    );
};
