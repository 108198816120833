import React from 'react';
import '../../styles/Modal.css';

export const DeleteUserModal = ({ showDeleteModal, closeModal, handleDeleteSubmit }) => {
    
    if (!showDeleteModal) return null;

    return (

        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Delete User</h3>
                <p>Are you sure you wish to delete this user?</p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={closeModal}>Cancel</button>
                    <button className="btn-modal" onClick={handleDeleteSubmit}>Delete</button>
                </div>
            </div>
        </>

    );
};
