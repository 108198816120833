import React, { useEffect, useState } from 'react';
import '../../styles/Timeline.css';
import MeetingService from '../../services/MeetingService';
import { useCu } from '../../context/CuContext';
import { NavLink } from 'react-router-dom';
import { DatetimeHelper } from '../../helpers/DatetimeHelper';

const baseScale = 1;
const adjacentScale = 1.25;
const hoverScale = 1.50;

function Timeline() {
    const { selectedCuId, setSelectedCuId } = useCu();
    const [containerWidth, setContainerWidth] = useState(0);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [mouseX, setMouseX] = useState(0);
    const [highestZIndex, setHighestZIndex] = useState(1);
    const [meetings, setMeetings] = useState([]);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    useEffect(() => {
        if (selectedCuId) {
            fetchMeetingsByCUID(selectedCuId);
            window.sessionStorage.setItem("selectedCuId", selectedCuId);
        } else {
            checkSessionStorageCuId();
        }
    }, [selectedCuId]);

    useEffect(() => {
        const updateWidth = () => setContainerWidth(document.querySelector('.card-container')?.offsetWidth || 0);
        updateWidth();
        window.addEventListener('resize', updateWidth);

        const handleMouseMove = (event) => {
            const timelineContainer = document.querySelector('.timeline-container');
            if (timelineContainer && event.clientY > timelineContainer.getBoundingClientRect().bottom) {
                closeDialog();
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('resize', updateWidth);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const fetchMeetingsByCUID = async (CUID) => {
        try {
            const response = await MeetingService.getMeetingsByCUID(CUID);
            const meetingsWithGroups = await Promise.all(response.map(async (meeting) => {
                const meetingGroups = await fetchMeetingGroupsByMeetingID(meeting.id);
                meeting.date = DatetimeHelper.toLocalISOString(DatetimeHelper.convertToLocal(meeting.date));
                return { ...meeting, groups: meetingGroups };
            }));
            meetingsWithGroups.sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date
            setMeetings(meetingsWithGroups);
        } catch (error) {
            console.error('Error fetching meetings:', error);
        }
    };

    const fetchMeetingGroupsByMeetingID = async (meetingID) => {
        try {
            const response = await MeetingService.getGroupsByMeetingID(meetingID);
            return response.map(group => group.name).join(", ");
        } catch (error) {
            console.error('Error fetching meeting groups:', error);
            return '';
        }
    };

    const checkSessionStorageCuId = () => {
        const cuId = window.sessionStorage.getItem("selectedCuId");
        if (cuId) {
            setSelectedCuId(cuId);
        }
    };

    const handleMouseEnter = (index, event) => {
        setHoveredIndex(index);
        setMouseX(event.clientX);
        setHighestZIndex(prevZIndex => prevZIndex + 1);
    };

    const closeDialog = () => {
        setHoveredIndex(null);
    };

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
    };

    const isPastOrToday = (date) => {
        return date <= today;
    };

    let nextMeetingIndex = null;
    let followingMeetingIndex = null;

    for (let i = 0; i < meetings.length; i++) {
        const meetingDate = new Date(meetings[i].date);
        if (meetingDate > today) {
            if (nextMeetingIndex === null) {
                nextMeetingIndex = i;
            } else {
                followingMeetingIndex = i;
                break;
            }
        }
    }

    return (
        <div className="timeline-container">
            <div className="card-container" ref={el => el && setContainerWidth(el.offsetWidth)}>
                {meetings.map((meeting, i) => {
                    const meetingDate = new Date(meeting.date);
                    const formattedDate = formatDate(meetingDate);
                    const isHovered = i === hoveredIndex;
                    const isAdjacent = hoveredIndex !== null && (i === hoveredIndex - 1 || i === hoveredIndex + 1);
                    const scale = isHovered ? hoverScale : isAdjacent ? adjacentScale : baseScale;
                    const opacity = isHovered ? 1 : isAdjacent ? 0.83 : 0.78;
                    const width = containerWidth / meetings.length * scale;
                    const boxShadowOffset = 4 * scale;
                    const boxShadowBlur = 10 * scale;
                    const boxShadowSpread = 3 * scale;
                    const boxShadowColor = `rgba(0, 0, 0, 0.5)`;
                    const boxShadow = `${boxShadowOffset}px ${boxShadowOffset}px ${boxShadowBlur}px ${boxShadowSpread}px ${boxShadowColor}`;
                    let className = `card ${isPastOrToday(meetingDate) ? 'card-today' : ''}`;
                    className += isHovered ? ' active-hovered' : isAdjacent ? ' card-adjacent' : ' card-farther';

                    if (i === nextMeetingIndex) {
                        className += ' card-next';
                    } else if (i === followingMeetingIndex) {
                        className += ' card-following';
                    }

                    const zIndex = isHovered ? highestZIndex : 1;

                    return (
                        <div
                            key={i}
                            className={className}
                            style={{ width, transform: `scale(${scale})`, opacity, boxShadow, zIndex }}
                            onMouseEnter={(e) => handleMouseEnter(i, e)}
                            onClick={() => setHoveredIndex(null)}
                        >
                            <div className="card-date">
                                {formattedDate}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="dialog-container" style={{ left: `${mouseX}px`, transform: 'translateX(-50%)' }}>
                {meetings.map((meeting, i) => {
                    const meetingDate = new Date(meeting.date);
                    if (!meeting) return null;

                    return (
                        <dialog
                            key={i}
                            className="dialog-box"
                            style={{
                                display: i === hoveredIndex ? 'block' : 'none',
                                bottom: `calc(100% + 15px)`,
                                minWidth: '250px',
                                maxWidth: '25%',
                            }}
                        >
                            <button className="close-button" onClick={closeDialog}>x</button>
                            <p><strong>Meeting:</strong>
                                <NavLink className="dialog-link" to={`/meeting-notes/${meeting.id}`}>
                                    {meeting.topics}
                                </NavLink>
                            </p>
                            <p><strong>Groups:</strong> {meeting.groups}</p>
                        </dialog>
                    );
                })}
            </div>
        </div>
    );
}

export default Timeline;