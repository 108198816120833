import React from 'react';
import '../../styles/Modal.css';

const DeleteGroupModal = ({ show, onClose, onConfirmDelete }) => {
    if (!show) return null;

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Delete Group</h3>
                <p>Deleting this group will also delete all tasks and subtasks assigned to it. Are you sure?</p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={onClose}>Cancel</button>
                    <button className="btn-modal" onClick={onConfirmDelete}>Delete</button>
                </div>
            </div>
        </>
    );
};

export default DeleteGroupModal;
