import React from 'react';
import '../../styles/Modal.css';

export const LogoutModal = ({ showLogoutModal, setShowLogoutModal }) => {

    const closeLogoutModal = () => {
        setShowLogoutModal(false)
        window.location.replace('/')
    }

    if (!showLogoutModal) return null;

    return (
        <>
            <div className="modal-overlay" onClick={() => closeLogoutModal()}></div>
            <div className="modal-content">
                <h3>Your session has expired</h3>
                <p>Please log back in to continue.</p>
                <div style={{justifyContent:'center'}} className="modal-footer">
                    <button style={{marginTop:'1rem'}} className="btn-modal" onClick={() => closeLogoutModal()}>Login</button>
                </div>
            </div>
        </>
    );
};