'use client';

import { useState, useEffect, useRef } from 'react';
import { NavLink, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import TTLogo from '../../assets/NavTTLogo.png';
import { useCu } from '../../context/CuContext';
import { useUserContext } from '../../context/UserContext';
import CreditUnionService from '../../services/CreditUnionService';
import { LoginService, setCookie } from '../../services/LoginService';
import '../../styles/NavigationBar.css';

export default function NavigationBar() {
    const [isMobile, setIsMobile] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeCredit, setActiveCredit] = useState(null);
    const [logoURL, setLogoURL] = useState();
    const { selectedCuId, setSelectedCuId, allCreditUnions } = useCu();
    const [creditUnions, setCreditUnions] = useState([]);
    const [username, setUsername] = useState('User');
    const { isAdmin, isSalesOrAdmin, setIsLoggedIn, setIsAdmin, setIsSalesOrAdmin } = useUserContext();
    const navigate = useNavigate();
    const location = useLocation();
    const navRef = useRef();
    const dropdownRef = useRef();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetchCreditUnions();
    }, [allCreditUnions]);

    useEffect(() => {
        if (selectedCuId) {
            fetchCreditUnionData();
        }
    }, [selectedCuId, allCreditUnions]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setIsMenuOpen(false);
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    async function fetchCreditUnions() {
        try {
            const userIsAdmin = await isAdmin;
            if (userIsAdmin) {
                const data = await CreditUnionService.getAllCreditUnions();
                setCreditUnions(data);
            } else {
                const userID = await LoginService.getCookie('id');
                const data = await CreditUnionService.getUsersCreditUnionsByUserId(userID);
                setCreditUnions(data);
            }
        } catch (error) {
            console.error('Fetching credit unions failed', error);
        }

        const nameCookie = LoginService.getCookie('name');
        if (nameCookie) {
            setUsername(nameCookie);
        }
    }

    async function fetchCreditUnionData() {
        try {
            const data = await CreditUnionService.getCreditUnionById(selectedCuId);
            setCULogo(data.brandingLogo);
        } catch (error) {
            console.error('Fetching selected credit union data failed', error);
        }
    }

    const setCULogo = async (brandingLogoKey) => {
        if (brandingLogoKey) {
            try {
                const newLogoURL = await CreditUnionService.getCULogo(brandingLogoKey);
                setLogoURL(newLogoURL);
            } catch (error) {
                console.error('Unable to fetch CU logo', error);
            }
        } else {
            setLogoURL(TTLogo);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLogout = () => {
        setCookie("id", "", -1);
        setCookie("token", "", -1);
        setCookie("name", "", -1);
        setCookie("email", "", -1);
        setCookie("role", "", -1);
        setCookie("lastActiveTime", "", -1);

        setIsLoggedIn(false);
        setIsAdmin(false);
        setIsSalesOrAdmin(false);

        window.location.replace("/");
    };

    const handleCreditUnionSelection = async (cuId) => {
        setSelectedCuId(cuId);
        setIsMenuOpen(false);
        setDropdownOpen(false);
        try {
            const data = await CreditUnionService.getCreditUnionById(cuId);
            setCULogo(data.brandingLogo);
            navigate(`/dashboard?cuId=${cuId}`);
        } catch (error) {
            console.error('Fetching selected credit union data failed', error);
        }
    };

    const handleManageAccounts = () => {
        navigate('/manage-accounts');
    };

    const toggleAccordion = () => {
        setActiveCredit((prev) => (prev === 'credit-unions' ? null : 'credit-unions'));
    };

    const displayTTlogo = () => {
        const routes = [{ path: "/manage-accounts" }, { path: "/credit-unions/disabled" }, { path: "/credit-unions" }];
        try {
            const [{ route }] = matchRoutes(routes, location);
            if (route) {
                return true;
            }
        } catch {
            return false;
        }
    };

    const buttonStyle = {
        fontWeight: 'bold',
        fontSize: 'medium',
        color: 'white',
        height: '3rem',
        width: '100%',
        margin: '0.5rem 0',
        padding: '0.5rem',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: 'center'
    };

    const staggeredLinkStyle = (index) => ({
        fontWeight: 'bold',
        fontSize: 'medium',
        padding: '0.5rem',
        width: '100%',
        textAlign: 'left',
        backgroundColor: index % 2 === 0 ? '#A6A6A6' : '#BFBFBF',
        border: 'none',
        cursor: 'pointer'
    });

    return (
        <nav className="navbar" ref={navRef}>
            {isMobile ? (
                <div className="mobile-container">
                    <div className="mobile-header" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <button
                            onClick={toggleMenu}
                            style={{
                                fontSize: '2rem',
                                padding: '0.5rem',
                                color: '#2563EB',
                                border: 'none',
                                background: 'none',
                                position: 'absolute',
                                left: '1rem', // Aligns the hamburger menu to the left
                                transform: isMenuOpen ? 'rotate(90deg)' : 'none',
                                transition: 'transform 0.3s ease'
                            }}
                        >
                            ☰
                        </button>
                        <img
                            src={(displayTTlogo() || !logoURL) ? TTLogo : logoURL}
                            alt="Credit Union Logo"
                            className="logo-img"
                            style={{ height: '75%', objectFit: 'contain' }}
                            onClick={() => navigate(`/dashboard?cuId=${selectedCuId}`)}
                        />
                    </div>
                    {isMenuOpen && (
                        <div className="mobile-menu" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                            <div className="accordion" style={{ width: '100%' }}>
                                <button className="accordion-trigger" onClick={toggleAccordion} style={buttonStyle}>
                                    Credit Unions
                                </button>
                                {activeCredit === 'credit-unions' && (
                                    <div className="accordion-content" style={{ width: '100%' }}>
                                        {creditUnions.map((creditUnion, index) => (
                                            <div
                                                key={creditUnion.id}
                                                className={`credit-union-link ${activeCredit === creditUnion.id ? 'font-bold text-black' : ''}`}
                                                onClick={() => handleCreditUnionSelection(creditUnion.id)}
                                                style={staggeredLinkStyle(index)}
                                            >
                                                {creditUnion.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <button className="manage-accounts-button" onClick={handleManageAccounts} style={buttonStyle}>Manage Accounts</button>
                            <button className="logout-button" onClick={handleLogout} style={buttonStyle}>Logout</button>
                        </div>
                    )}
                </div>
            ) : (
                <div className="desktop-container">
                    <div className="dropdown-container" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <div className="navigation-dropdown" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <button className="navigation-dropdown-trigger" onClick={() => setDropdownOpen(!dropdownOpen)} style={buttonStyle}>
                                Credit Unions
                            </button>
                            {dropdownOpen && (
                                <div className="navigation-dropdown-content">
                                    {creditUnions.map((creditUnion, index) => (
                                        <div
                                            key={creditUnion.id}
                                            className={`dropdown-item ${activeCredit === creditUnion.id ? 'font-bold text-black' : ''}`}
                                            onClick={() => handleCreditUnionSelection(creditUnion.id)}
                                            style={staggeredLinkStyle(index)}
                                        >
                                            {creditUnion.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <button className="manage-accounts-button" onClick={handleManageAccounts} style={buttonStyle}>Manage Accounts</button>
                    </div>
                    <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        <img src={(displayTTlogo() || !logoURL) ? TTLogo : logoURL} alt="Credit Union Logo" className="logo-img" style={{ height: '75%', objectFit: 'contain' }} onClick={() => navigate(`/dashboard?cuId=${selectedCuId}`)} />
                    </div>
                    <div className="welcome-container" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <div className="welcome-message">Welcome, {username}!</div>
                        <button className="logout-button" onClick={handleLogout} style={buttonStyle}>Logout</button>
                    </div>
                </div>
            )}
        </nav>
    );
}
