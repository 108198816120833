const groupTemplates = [

    {
        'id': 1,
        'name': "Marketing",
        'tasks': [
            {
                "name": "Task 1",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 1",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                        "attachments": [
                            {
                                "subtaskId": 0,
                                "meetingId": 0,
                                "name": "testEXCEL.xlsx",
                                "key": "TemplateAttachments/testEXCEL.xlsx"
                            },
                            {
                                "subtaskId": 0,
                                "meetingId": 0,
                                "name": "testWORD.docx",
                                "key": "TemplateAttachments/testWORD.docx"
                            },

                        ]
                    },
                    {
                        "name": "Subtask 2",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 3",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 2",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 4",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 5",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 6",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 3",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 7",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 8",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 9",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },
        ]
    },


    {
        'id': 2,
        'name': "Sales",
        'tasks': [
            {
                "name": "Task 1",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 1",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 2",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 3",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 2",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 4",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 5",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 6",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 3",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 7",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 8",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 9",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },
        ]
    },




    {
        'id': 3,
        'name': "Operations",
        'tasks': [
            {
                "name": "Task 1",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 1",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 2",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 3",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 2",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 4",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 5",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 6",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 3",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 7",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 8",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 9",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },
        ]
    },





    {
        'id': 4,
        'name': "IT",
        'tasks': [
            {
                "name": "Task 1",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 1",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 2",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 3",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 2",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 4",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 5",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 6",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 3",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 7",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 8",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 9",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },
        ]
    },




    {
        'id': 5,
        'name': "Legal",
        'tasks': [
            {
                "name": "Task 1",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 1",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 2",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 3",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 2",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 4",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 5",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 6",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 3",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 7",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 8",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 9",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },
        ]
    },



    {
        'id': 6,
        'name': "Product",
        'tasks': [
            {
                "name": "Task 1",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 1",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 2",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 3",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 2",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 4",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 5",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 6",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 3",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 7",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 8",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 9",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },
        ]
    },



    {
        'id': 7,
        'name': "Billing",
        'tasks': [
            {
                "name": "Task 1",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 1",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 2",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 3",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 2",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 4",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 5",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 6",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },


            {
                "name": "Task 3",
                "description": "string",
                'subtasks': [
                    {
                        "name": "Subtask 7",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 8",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                    {
                        "name": "Subtask 9",
                        "description": "string",
                        "dueDate": "2025-01-30T12:00:00.000Z",
                        "isComplete": false,
                    },
                ]
            },
        ]
    },






];


export default groupTemplates;