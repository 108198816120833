import React, { useState } from 'react';
import '../../styles/Modal.css';

const EditGroupModal = ({ show, onClose, onEditGroup, groupToEdit }) => {
    const [groupName, setGroupName] = useState(groupToEdit.name);

    const handleSubmit = (event) => {
        event.preventDefault();
        onEditGroup({ id: groupToEdit.id, name: groupName, cuId: groupToEdit.cuId });
        setGroupName(''); // Reset the form input
        onClose();
    };

    if (!show) return null;

    return (
        <div>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Edit Group</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <input
                            type="text"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            className="modal-input"
                            required
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditGroupModal;
