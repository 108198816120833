import '../../styles/ManageAccts.css';
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import UserService from "../../services/UserService";
import { getRoleNamesFromNumbers } from "../../services/RoleService";
import { AddUserModal } from './AddUserModal';
import { DeleteUserModal } from './DeleteUserModal';
import CreditUnionService from '../../services/CreditUnionService';
import { EditUserModal } from './EditUserModal';
import { RolesModal } from './RolesModal';
import { useUserContext } from '../../context/UserContext';

export const ManageAccts = () => {

    const { isAdmin } = useUserContext();
    const [isLoading, setIsLoading] = useState(true);

    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    //users filter just uses input value UNLESS rerendering, in which case it filters using this state 
    //this maintains the filter through re-renders
    const [filterOnRender, setFilterOnRender] = useState("");

    const [allCreditUnions, setAllCreditUnions] = useState([]);
    const [selectedCUID, setSelectedCUID] = useState();

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRolesModal, setShowRolesModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    const [reRenderToggle, setReRenderToggle] = useState(false)

    const [userToEdit, setUserToEdit] = useState({});

    useEffect(() => {
        fetchUsers();
        fetchCreditUnions();
    }, [reRenderToggle])

    const reRender = () => {
        reRenderToggle ? setReRenderToggle(false) : setReRenderToggle(true)
    }

    const filterUsers = (filteredText) => {
        if (filteredText.length > 0) {
            setFilteredUsers(
                allUsers.filter((user) =>
                    user.lastName.toLowerCase().startsWith(
                        filteredText.toLowerCase()
                    ) || user.firstName.toLowerCase().startsWith(
                        filteredText.toLowerCase()
                    )
                )
            )
        }
        else {
            setFilteredUsers(allUsers)
        }
    };

    const fetchUsers = async () => {
        await setIsLoading(true);

        try {
            let response = await UserService.getAllUsers();

            const userIsAdmin = await isAdmin

            //filter TTadmin and TTsales users from the response if loggedin user's role is TTsales
            if (!userIsAdmin) {
                response = response.filter(u => u.role === 3)
                console.log('user is NOT an admin')
            }

            //replace role numbers with names    
            const usersWithRoles = await getRoleNamesFromNumbers(response);
            await setAllUsers(usersWithRoles)

            if (filterOnRender.length > 0) {
                setFilteredUsers(
                    usersWithRoles.filter((user) =>
                        user.lastName.toLowerCase().startsWith(
                            filterOnRender.toLowerCase()
                        ) || user.firstName.toLowerCase().startsWith(
                            filterOnRender.toLowerCase()
                        )
                    )
                )
            }


            else {
                await setFilteredUsers(usersWithRoles)
            }


        } catch (error) {
            console.error('Fetching users failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCreditUnions = async () => {
        await setIsLoading(true);
        try {
            const response = await CreditUnionService.getAllCreditUnions();
            await setAllCreditUnions(response)
        } catch (error) {
            console.error('Fetching users failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddUser = async (newUserData) => {
        try {
            setIsLoading(true);
            await UserService.createUser({ ...newUserData });
            fetchUsers();
        } catch (error) {
                if(error.detail)
                {
                    alert(error.detail)
                    setIsLoading(false)
                }
        }
        setShowAddModal(false);
    };

    const handleEditUser = async (editedUserData) => {
        try {
            await UserService.editUser({ ...editedUserData });
            fetchUsers();
        } catch (error) {
            console.error('Failed to edit user', error);
        }
        setShowEditModal(false);
    };

    const handleDeleteSubmit = async () => {

        try {
            //only delete user if last name is NOT 'DO NOT DELETE'
            if (userToDelete.lastName != 'DO NOT DELETE') {
                await UserService.deleteUser(userToDelete.id);
                fetchUsers();
            }
            else {
                alert('Unable to delete Default Test Users')
            }
        } catch (error) {
            console.error('Failed to delete user', error);
        }
        setShowDeleteModal(false);
    };

    const handleEditPrompt = async (event) => {
        event.preventDefault();
        const [, userID] = event.target.id.split("--")
        const parsedUserID = parseInt(userID)
        const foundUser = await UserService.getUserById(parsedUserID)
        await setUserToEdit(foundUser)
        await setShowEditModal(true)
    }

    const handleDeletePrompt = async (event) => {
        event.preventDefault();
        const [, userID] = event.target.id.split("--")
        const parsedUserID = parseInt(userID)
        const foundUser = await UserService.getUserById(parsedUserID)
        await setUserToDelete(foundUser)
        setShowDeleteModal(true)
    }

    const handleAddRemoveCU = async (event) => {
        event.preventDefault();

        //first, make sure selectedCUID not 0
        if (parseInt(selectedCUID) === 0) {
            alert('please select a credit union to add/remove')
            return
        }

        const [, userID] = event.target.id.split("--")
        const parsedUserID = parseInt(userID)

        const selectedUser = allUsers.find(u => u.id === parsedUserID)

        const matchingUsersCreditUnion = selectedUser.usersCreditUnions.find(cu => (cu.cuId === parseInt(selectedCUID)))

        try {
            //if credit union already assigned, remove it
            if (matchingUsersCreditUnion != null) {
                const response = CreditUnionService.removeAUsersCreditUnion(matchingUsersCreditUnion.id)
                    .then(() => reRender())
                return response
            }

            //if credit union NOT assigned, add it using userID and cuID
            else {

                // before adding a new Credit Union, make sure user is TTsales or TTadmin
                let isSalesOrAdmin
                if (selectedUser.role === "TTadmin" || selectedUser.role === "TTsales") {
                    isSalesOrAdmin = true
                }
                else {
                    isSalesOrAdmin = false
                }

                // if not an admin, check if length of selectedUser.userCreditUnions is greater than 0
                if (!isSalesOrAdmin) {
                    // if already assigned credit union, return error
                    if (selectedUser.usersCreditUnions.length > 0) {
                        alert('\nUsers with the role "CUuser" may only be assigned a single Credit Union. \n\nIn order to change their assigned Credit Union, you must first remove the one that is assigned')
                        return
                    }
                }

                // otherwise, add CU to user
                const response = CreditUnionService.addACreditUnionToUser(selectedUser.id, selectedCUID)
                    .then(() => reRender())
                return response

            }
        } catch (error) {
            console.log('unable to add/delete credit union', error)
        }

    }

    return (

        <>
            <h1>User Accounts</h1>
            <div className="manageacct-buttons">

                <button className='manageacct-button' onClick={() => setShowAddModal(true)}>Add User</button>
                <NavLink to='/credit-unions'>
                    <button className='manageacct-button'>Edit Credit Unions</button>
                </NavLink>

                {/* if user is Admin, display Role Definitions button */}
                {isAdmin &&
                    <button className='manageacct-button' onClick={() => setShowRolesModal(true)}>View Role Definitions</button>
                }

            </div>



            <div className='filter-container' style={{ marginTop: '1rem' }}>
                <label htmlFor="filter-input" style={{ marginRight: '.5rem' }}>Filter by Name</label>
                <input
                    id='filter-input'
                    type="text"
                    onChange={
                        (event) => {
                            filterUsers(event.target.value)
                            setFilterOnRender(event.target.value)
                        }
                    }
                    placeholder=""
                    required
                    className="modal-input"
                />
            </div>




            <div className="users-section">

                {isLoading ? (
                    <div>Loading...</div>
                ) : filteredUsers.length > 0 ?

                    <table className="users-table">
                        <thead>
                            <tr>
                                <th className='tableHeader--1'>Full Name</th>
                                <th className='tableHeader--2'>Contact Email</th>
                                <th className='tableHeader--3'>Username</th>
                                <th className='tableHeader--4'>Role</th>
                                <th className='tableHeader--5'>Credit Union Assignment</th>
                                <th className='tableHeader--6'>Notifications</th>
                                <th className='tableHeader--7'>Delete</th>

                            </tr>
                        </thead>

                        <tbody>

                            {filteredUsers.map((user) => {

                                //create a list of all the user's CUs, into a string
                                let listString = ""

                                if (user.role === "TTadmin") {
                                    listString = "All Credit Unions (default)"
                                }

                                else {
                                    user.usersCreditUnions.map((ucu) => {
                                        listString = listString + ucu.cu.name + "\n"
                                    })
                                }

                                return (
                                    <tr key={`user--${user.id}`}>
                                        <td>{user.firstName} {user.lastName}</td>
                                        <td>{user.email}</td>
                                        <td>{user.username}</td>
                                        <td>{user.role}</td>
                                        <td>

                                            <div className='cu-assignment-container'>
                                                <div>
                                                    <div>Currently Assigned</div>
                                                    <textarea className='cu-list' cols={20} rows={4} value={listString} readOnly></textarea>
                                                </div>

                                                {/* if user is TTadmin, don't display dropdown or add/remove button */}
                                                {(user.role === "TTadmin") ? <></> :

                                                    <div className='cu-dropdown-container'>
                                                        <select className="cu-assignment-dropdown" defaultValue={0} onChange={(e) => setSelectedCUID(e.target.value)}>

                                                            <option value={0}>Select a Credit Union</option>

                                                            {allCreditUnions.map((cu) => (
                                                                <option key={`creditUnion--${cu.id}`} value={cu.id}>{cu.name}</option>
                                                            ))}

                                                        </select>

                                                        <button className="user-button blue" id={'addCUtouser--' + user.id} onClick={(e) => handleAddRemoveCU(e)}>Add/Remove CU</button>
                                                    </div>
                                                }

                                            </div>


                                        </td>
                                        <td><input type="checkbox" onChange={() => { }} checked={user.receivesNotifs} /></td>
                                        <td>
                                            <div className='user-buttons-container'>
                                                <button className='user-button blue' id={`editUser--${user.id}`} tag={Link} onClick={(evt) => handleEditPrompt(evt)}>Edit</button>
                                                <button className='user-button red' id={`deleteUser--${user.id}`} color="danger" tag={Link} onClick={(evt) => handleDeletePrompt(evt)}>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                    : <><h1>No users found</h1></>
                }

                {showAddModal && <AddUserModal showAddModal={showAddModal} closeModal={() => setShowAddModal(false)} handleAddUser={handleAddUser} />}
                {showEditModal && <EditUserModal showEditModal={showEditModal} closeModal={() => setShowEditModal(false)} handleEditUser={handleEditUser} userToEdit={userToEdit} setUserToEdit={setUserToEdit} />}
                {showDeleteModal && <DeleteUserModal showDeleteModal={showDeleteModal} closeModal={() => setShowDeleteModal(false)} handleDeleteSubmit={handleDeleteSubmit} />}
                {showRolesModal && <RolesModal showRolesModal={showRolesModal} closeModal={() => setShowRolesModal(false)} />}

            </div>
        </>
    )
}