import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginService } from "../../services/LoginService";
import '../../styles/Auth.css'


export const ForgotUsername = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [emailState, setEmailState] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        await setIsLoading(true);
        await LoginService.handleForgotUsername(emailState)
            .then(response => alert(response.message))
            .catch(error => {
                if (error.detail) {
                    alert(error.detail)
                }
            });

        
        navigate("/")
    }




    return (


        <main id="login" className="page">

            <img src={'https://trutreasuryrms.com/images/logo.png'} alt='logo' className='logo' />

            <section aligncontent="center" className="page-form">

                <form onSubmit={(evt) => handleSubmit(evt)} className="page-inner-form">
                    <h3 className="page-text">Forgot Username</h3>

                    <label htmlFor="email" className="page-text">Please enter the email address associated with your account</label>

                    {isLoading ? <h3>Loading...</h3> : 
                        <>
                            <input
                                onChange={e => setEmailState(e.target.value)}
                                name="email"
                                id="email"
                                autoFocus="on"
                                value={emailState}
                                autoComplete="off"
                                className="page-input"
                            />

                            <button
                                className="page-button"
                                type="submit">
                                Submit
                            </button>
                        </>
                    
                    }

                </form>

                <Link to='/' className="page-link">Cancel</Link>

            </section>
        </main>
    );
}