import React, { useState } from 'react';
import '../../styles/Modal.css';

export const AddAttachmentModal = ({ showModal, closeModal, handleAddAttachment }) => {

    const [file, setFile] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();

        //create form data
        const formData = new FormData()
        formData.append('file', file)

        handleAddAttachment(formData)
        closeModal();
    };

    if (!showModal) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h3>Upload Attachment</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">

                        <input
                            type="file"
                            value={file.fileName}
                            onChange={(e) => {setFile(e.target.files[0])}}
                            required
                            className="modal-input"
                        />

                    </div>

                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Upload</button>
                        <button type="button" className="btn-modal" onClick={closeModal}>Cancel</button>
                    </div>

                </form>

            </div>
        </>
    );
};