import { API_URL } from "../config";
import { handleExpiredToken, handleResponse, handleError } from "../helpers/ServicesHelper";
import { LoginService } from "./LoginService";

const SubtaskService = {

    getSubtasks: () => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Subtasks`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    getSubtasksByTaskId: (taskId) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Subtasks/${taskId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).then(handleResponse).catch(handleError);
    },

    createSubtask: async (subtaskData) => {
        try {
            const userToken = LoginService.getCookie('token');
            const response = await fetch(`${API_URL}/Subtasks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
                body: JSON.stringify(subtaskData),
            });
            const checkedResponse = await handleExpiredToken(response)
            return await handleResponse(checkedResponse);
        } catch (error) {
            handleError(error);
        }
    },

    updateSubtask: (id, subtaskData) => {
        const userToken = LoginService.getCookie('token');
        return fetch(`${API_URL}/Subtasks/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            body: JSON.stringify(subtaskData),
        }).then(handleExpiredToken).catch(handleError);
    },

    deleteSubtask: (id) => {
        const userToken = LoginService.getCookie('token')
        return fetch(`${API_URL}/Subtasks/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + userToken
            },
        }).then(handleExpiredToken).catch(handleError);
    },
};

export default SubtaskService;