import React, { useState } from 'react';
import '../../styles/Modal.css';
import GroupTemplates from '../../helpers/GroupTemplates';

export const AddCUModal = ({ showAddModal, closeModal, handleAddCU }) => {

    const [name, setName] = useState('')
    const [logo, setLogo] = useState('')
    const [selectedTemplates, setSelectedTemplates] = useState([]);

    const handleCheckboxChange = (template) => {
        // Add or Remove (filter out and set) template based on whether it is already included in the selectedTemplates array, or not
        if (selectedTemplates.includes(template)) {
            setSelectedTemplates(selectedTemplates.filter(t => t !== template));
        } else {
            setSelectedTemplates([...selectedTemplates, template]);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleAddCU(name, logo, selectedTemplates)
        closeModal();
    };

    if (!showAddModal) {
        return null;
    }

    return (
        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modal-content">
                <h2>Add New Credit Union</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">

                    <h3 style={{margin: 0}}>Name</h3>
                        <input
                            id='name-input'
                            type="text"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            placeholder="Credit Union Name"
                            required
                            className="modal-input"
                        />

                        <h3 style={{marginBottom: 0}}>Upload CU Logo</h3>
                        <input
                            id='add-logo-file-input'
                            type="file"
                            value={logo.fileName ? logo.fileName : undefined}
                            onChange={(e) => {
                                if (e.target.files.length > 0) {
                                    setLogo(e.target.files[0])
                                }
                                else (
                                    setLogo([])
                                )
                            }}
                            required
                            className="modal-input cu-file-input"
                        />

                    
                            
                        <h3 style={{marginBottom: 0}}>Generate Default Groups</h3>
                        <div className='template-groups-container'>
                        {GroupTemplates.map((template) => (
                            <div key={`template-group-${template.id}`}>
                                <input
                                    type="checkbox"
                                    id={`template-checkbox-${template.id}`}
                                    checked={selectedTemplates.includes(template)}
                                    onChange={() => handleCheckboxChange(template)}
                                />
                                <label htmlFor={`template-checkbox-${template.id}`}>{template.name}</label>
                            </div>
                            
                        ))}
                        </div>






                    </div>

                    <div className="modal-footer">
                        <button type="submit" className="btn-modal">Save</button>
                        <button type="button" className="btn-modal" onClick={closeModal}>Cancel</button>
                    </div>

                </form>

            </div>
        </>
    );
};