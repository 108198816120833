import React from 'react';
import '../../styles/Modal.css';
import MeetingService from "../../services/MeetingService";

const DeleteMeetingModal = ({ show, onClose, selectedMeetingID }) => {

    if (!show) return null;

    const handleConfirmDelete = async () => {
        console.log(selectedMeetingID);
        try {
            await MeetingService.deleteMeeting(selectedMeetingID);
        } catch (error) {
            console.error('Error deleting meetings:', error);
        } finally {
            onClose();
        }
    };

    return (
        <>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h3>Delete Meeting</h3>
                <p>Deleting this meeting will also delete all meeting related data, including . Are you sure?</p>
                <div className="modal-footer">
                    <button className="btn-modal" onClick={onClose}>Cancel</button>
                    <button className="btn-modal" onClick={handleConfirmDelete}>Delete</button>
                </div>
            </div>
        </>
    );
};

export default DeleteMeetingModal;
